module.exports = {
  content: ['./src/**/*.{js,jsx}'],
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      white: '#ffffff',
      black: '#2E2725',
      yellow: '#fdba12',
      background: '#f2f2f2',
      gray50: '#f9fafb',
      gray100: '#f3f4f6',
      gray200: '#e5e7eb',
      gray300: '#d1d5db',
      gray400: '#9ca3af',
      gray500: '#6b7280',
      gray600: '#4b5563',
      gray700: '#374151',
      gray800: '#1f2937',
      gray900: '#111827',
      'micro-generator-used': '#8E3200',
      'micro-generator-not-used': '#9a9a9a',
      'micro-shortage': '#E80E2E',
      'micro-solar-curtailed': '#FF8C1E',
      'micro-solar': '#FDBA12',
      'micro-solar-battery': '#B980F0',
      'micro-solar-grid': '#10A19D',
      'micro-genset-to-grid': '#1c4d9c',
      'micro-genset-to-battery': '#019267',
      'micro-battery-supply': '#00C86E',
      'micro-battery-soc': '#00DCAA',
      'micro-grid': '#5A8CDC',
      'micro-grid-battery': '#FF8787',
      'micro-genset-used': '#8E3200',
      'micro-genset-curtailed': '#8291AA',
      'micro-load-profile': '#000000',
      'micro-min-soc': '#8787FA',
      'micro-max-soc': '#cfcffd',
      success: '#82cd64',
      error: '#a51c30',
    },
    fontFamily: {
      univers: ['univers', 'sans-serif'],
      'univers-light': ['univers-light', 'sans-serif'],
      'univers-bold': ['univers-bold', 'sans-serif'],
      'univers-condensed': ['univers-condensed', 'sans-serif'],
      'univers-condensed-bold': ['univers-condensed-bold', 'sans-serif'],
    },
    extend: {
      boxShadow: {
        micro: '0 5px 20px 0 rgba(146, 146, 157, 0.05)',
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
  },
  plugins: [],
};
