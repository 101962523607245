import ShareButton from "../Buttons/ShareButton";

/**
 * Tab description
 *
 * @param {string} props // Component properties
 *
 * @return {JSX.Element}
 */
export default function TabContent(props) {
  const { title, content } = props;
  return (
    <div className="col-span-8">
      <div className="flex align-center">
        <h1 className="text-4xl font-medium uppercase">{title}</h1>
        <ShareButton />
      </div>
      <p className="py-5 font-univers-light">{content}</p>
    </div>
  );
}
