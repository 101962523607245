export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;

export const timesAM = [
  { value: '12:00', key: '12am' },
  { value: '1:00', key: '1am' },
  { value: '2:00', key: '2am' },
  { value: '3:00', key: '3am' },
  { value: '4:00', key: '4am' },
  { value: '5:00', key: '5am' },
  { value: '6:00', key: '6am' },
  { value: '7:00', key: '7am' },
  { value: '8:00', key: '8am' },
  { value: '9:00', key: '9am' },
  { value: '10:00', key: '10am' },
  { value: '11:00', key: '11am' },
];
export const timesPM = [
  { value: '12:00', key: '12pm' },
  { value: '1:00', key: '1pm' },
  { value: '2:00', key: '2pm' },
  { value: '3:00', key: '3pm' },
  { value: '4:00', key: '4pm' },
  { value: '5:00', key: '5pm' },
  { value: '6:00', key: '6pm' },
  { value: '7:00', key: '7pm' },
  { value: '8:00', key: '8pm' },
  { value: '9:00', key: '9pm' },
  { value: '10:00', key: '10pm' },
  { value: '11:00', key: '11pm' },
];

export const hourlyDemandType = 'hourlyDemand';
export const hourlyDemandPrefilledType = 'hourlyDemandPrefilled';
export const uploadSolarProfileType = 'uploadSolarProfile';
export const surfaceAreaType = 'surfaceArea';
export const efficiencyType = 'efficiency';
export const batteryNameType = 'batteryName';
export const batteryCapacityType = 'batteryCapacity';
export const gridPowerImportType = 'gridPowerImport';
export const gridPowerExportType = 'gridPowerExport';
export const gridToBatType = 'gridToBat';
export const generatorType = 'generator';
export const purchasingPriceType = 'purchasingPrice';
export const sellingPriceType = 'sellingPrice';
export const scenarioType = "scenario";
export const beginDateType = "beginDate";
export const endDateType = "endDate";
export const runIdType = "runId";

export const maximumScenarios = 5;

export const periodDayHours = "Day-hours";
export const periodDay = "Day";

export const notificationTypeError = 'error';
export const notificationTypeSuccess = 'success';

export const initialGenerator = {
  type: '',
  capacity: 0,
  min_power_perc: 0,
  price: 0,
  operational_cost: 0,
  emmission_co2: 0,
  emmission_NOx: 0,
};
export const initialBattery = {
  name: '',
  capacity: 0,
  inverter_capacity: 0,
  min_soc: 0,
  max_soc: 0,
  price: 0,
  dcac_efficiency: 100
};
export const initialScenario = {
  scenario: "standard",
  begin_date: "",
  end_date: "",
  grid_to_bat: false,
};

export const navigationItems = [
  { title: "Input", url: "/", active: false },
  { title: "Overview", url: "/overview", active: false },
  { title: "Power", url: "/power", active: false },
  { title: "Solar", url: "/solar", active: false },
  { title: "Grid", url: "/grid", active: false },
  { title: "Generators", url: "/generators", active: false },
];
export const scenarios = [
  { title: 'Standard', value: 'standard' },
  { title: 'Summer', value: 'summer' },
  { title: 'Winter', value: 'winter' },
]

/**
 * Get the screensize
 *
 * @return {number}
 */
export const screenWidth = window.innerWidth;

export const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
export const API_ROOT = process.env.REACT_APP_API_ROOT;

export const TIME_TO_READ = 2000;

export const notNeededKeys = ['month', 'label', 'Load Profile'];
