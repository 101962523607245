/**
 * Customized label
 *
 * @return {JSX.Element}
 */
export default function CustomizedLabel({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    fill,
  }) {
    // Calculate the position for the Text element
    const radian = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * radian);
    const y = cy + radius * Math.sin(-midAngle * radian);

    return (
      // this element returns the text label placed in the right spot based on above calculations
      <text
        x={x}
        y={y}
        fill={fill}
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${percent ? `${(percent * 100).toFixed(0)}%` : ''}`}
      </text>
    );
  };
