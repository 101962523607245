import { beginDateType, endDateType, gridToBatType, scenarioType, scenarios } from "../../Constants";
import { Button } from "../Buttons/Button";

export const ScenarioSelect = ({index, scenario, setScenarios, removeScenario}) => {

  const updateScenario = ({type, value}) => {
    setScenarios((prevState) => {
      const newState = prevState.map((scenario, key) => {
        if (key === index) {
          switch (type) {
            case scenarioType:
              return {...scenario, scenario: value};
            case beginDateType:
              return {...scenario, begin_date: value};
            case endDateType:
              return {...scenario, end_date: value};
            case gridToBatType:
              return {...scenario, grid_to_bat: value};
            default:
              return {...scenario};
          }
        }

        return {...scenario};
      });
      return newState;
    });
  }

  return (
    <>
      <div className="col-span-6 sm:col-span-2">
        <label htmlFor="scenario" className="block text-sm font-medium">
          Scenario
        </label>
        <select
          id="scenario"
          name="scenario"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-md"
          onChange={(e) =>
            updateScenario({
              type: scenarioType,
              value: e.target.value,
            })
          }
          value={scenario?.scenario ?? ''}
        >
          {scenarios.map((scenario) => {
            return (
              <option value={scenario.value} key={scenario.title}>
                {scenario.title}
              </option>
            );
          })}
        </select>
      </div>

      <div className="col-span-6 sm:col-span-1">
        <label htmlFor="scenario" className="block text-sm font-medium">
          Begin date
        </label>
        <input
          className="mt-1 block w-full pl-3 pr-2 py-2 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-md"
          type="date"
          max={scenario?.end_date ?? ''}
          value={scenario?.begin_date}
          onChange={(e) =>
            updateScenario({
              type: beginDateType,
              value: e.target.value,
            })
          }
        />
      </div>

      <div className="col-span-6 sm:col-span-1">
        <label htmlFor="scenario" className="block text-sm font-medium">
          End date
        </label>
        <input
          className="mt-1 block w-full pl-3 pr-2 py-2 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-md"
          type="date"
          min={scenario?.begin_date ?? ''}
          value={scenario?.end_date}
          onChange={(e) =>
            updateScenario({
              type: endDateType,
              value: e.target.value,
            })
          }
        />
      </div>
      <div className="col-span-6 sm:col-span-1">
      <label
          htmlFor="grid-to-battery"
          className="block text-sm font-medium"
        >
          Grid to battery
        </label>
        <div className="relative inline-block w-14 h-8 mt-1">
          <input
            type="checkbox"
            name="grid-to-battery"
            id="grid-to-batter"
            className="absolute z-10 opacity-0 w-14 h-8 cursor-pointer"
            onChange={(e) =>
              updateScenario({
                type: gridToBatType,
                value: !scenario?.grid_to_bat,
              })
            }
            value={
              scenario?.grid_to_bat
                ? scenario?.grid_to_bat
                : false
            }
          />
          <span
            className={`absolute inset-0 before:absolute before:content-[''] before:h-6 before:w-6 before:left-1 before:bottom-1 before:bg-white ${
              scenario?.grid_to_bat
                ? 'bg-yellow before:translate-x-6'
                : 'bg-gray200'
            }`}
          />
        </div>
      </div>
      <div className="col-span-6 sm:col-span-1 flex items-end">
        {/* Make sure we can't delete the first scenario item. */}
        {0 !== index &&
          <Button variant="secondary" onClick={(e) => removeScenario(e, index)}>
            Remove scenario
          </Button>
        }
      </div>
    </>
  );
}
