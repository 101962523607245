import { LinkIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { TIME_TO_READ } from "../../Constants";
import useInputContext from "../../hooks/useInputContext";

/**
 * Button to copy the current URL to clipboard.
 *
 * @return {JSX.Element}
 */
export default function ShareButton() {
  const { runId } = useInputContext();
  const [buttonText, setButtonText] = useState("Share configuration");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setButtonText("Share configuration");
    }, TIME_TO_READ);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [buttonText]);

  return (
    <button
      className="flex px-3 py-2 ml-6 items-center w-max bg-black text-white hover:bg-yellow"
      onClick={() => {
        navigator.clipboard.writeText(
          `${window.location.origin}?run_id=${runId}`
        );
        setButtonText("Link copied");
      }}
    >
      <span className="mr-1 font-bold text-sm uppercase">{buttonText}</span>
      <LinkIcon className="flex-shrink-0 h-4 w-4" />
    </button>
  );
}
