import { theme } from '../tailwind.config';

/**
 * Get the color
 *
 * @param {string} color // The action
 *
 * @return {string}
 */
export function colorPicker(color) {
  switch (color) {
    case 'Battery SOC %':
      return theme.colors['micro-battery-soc'];
    case 'Battery':
    case 'Battery Supply':
      return theme.colors['micro-battery-supply'];
    case 'Genset Curtailed':
    case 'Remaining Available Power':
    case 'Diesel Generator usage':
    case 'Diesel Generator':
    case 'Diesel Generator (max)':
      return theme.colors['micro-genset-curtailed'];
    case 'Genset Used':
    case 'Generator':
    case 'Gas Generator (max)':
    case 'Gas Generator usage':
    case 'Gas Generator':
    case 'used':
    case 'max':
      return theme.colors['micro-genset-used'];
    case 'unused':
      return theme.colors['micro-genset-unused'];
    case 'Genset to Battery':
      return theme.colors['micro-genset-to-battery'];
    case 'Genset to Grid':
      return theme.colors['micro-genset-to-grid'];
    case 'Grid':
    case 'Grid (max)':
    case 'Used Grid Power':
      return theme.colors['micro-grid'];
    case 'Grid to Battery':
      return theme.colors['micro-grid-battery'];
    case 'Shortage':
    case 'Power Shortage':
    case 'mean':
      return theme.colors['micro-shortage'];
    case 'Solar Curtailed':
      return theme.colors['micro-solar-curtailed'];
    case 'Solar Used':
    case 'Solar':
    case 'Used Solar Power':
    case 'Solar Production':
      return theme.colors['micro-solar'];
    case 'Solar to Battery':
      return theme.colors['micro-solar-battery'];
    case 'Solar to Grid':
      return theme.colors['micro-solar-grid'];
    case 'Always full':
    case 'Not filled in given period':
      return theme.colors['micro-battery-not-used'];
    case 'Used range battery level':
      return theme.colors['micro-grid'];
    default:
      return theme.colors.black;
  }
}
