import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuthUser from "../../hooks/useAuthUser";
import useInputContext from "../../hooks/useInputContext";
import { CogIcon } from "@heroicons/react/solid";

/**
 * The header component
 *
 * @return {JSX.Element}
 */
export default function Header() {
  const { user } = useAuthUser();
  const userName = user?.given_name || null;
  const { runId } = useInputContext();
  const navigate = useNavigate();

  const [navigationItems, setNavigationItems] = useState([
    { title: 'Input', url: '/', active: true },
    { title: 'Overview', url: '/overview', active: false },
    { title: 'Power', url: '/power', active: false },
    { title: 'Solar', url: '/solar', active: false },
    { title: 'Grid', url: '/grid', active: false },
    { title: 'Generators', url: '/generators', active: false },
  ]);
  const currentUrl = window.location.pathname;

  /**
   * Set the active navigation item.
   *
   * @param {string} activeItem // Navigation item title
   */
  function setActiveUrl(activeItem) {
    const newState = navigationItems.map((navigationItem) => {
      if (
        navigationItem.title === activeItem ||
        navigationItem.url === activeItem
      ) {
        return { ...navigationItem, active: true };
      }

      return { ...navigationItem, active: false };
    });

    setNavigationItems(newState);
  }

  /**
   * button click event handler
   * @param {object} e
   * @return {undefined}
   */
  function handleButtonClick(e) {
    e.preventDefault();
    navigate("/admin-portal");
  }

  useEffect(() => {
    setActiveUrl(currentUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  return (
    <>
      <header className="fixed bg-black shadow">
        <nav className="relative bg-yellow">
          <div
            className="container mx-auto px-4 sm:px-6 lg:px-8"
            aria-label="Top"
          >
            <div className="w-full py-2 grid grid-cols-2">
              <Link className="flex items-center" to="/">
                <span className="sr-only">Pon Cat</span>
                <img className="h-8 w-auto" src="logo.svg" alt="Pon Cat Logo" />
                <strong className="pl-3">MicroGrid</strong>
              </Link>
              <div className="col-end-4 flex items-center">
                <strong className="font-univers-condensed invisible md:visible">
                  {userName ? `Hello, ${userName}` : 'Hello'}
                </strong>
                <button onClick={handleButtonClick} className="px-3 py-2">
                  <span className="sr-only">Admin</span>
                  <CogIcon className="h-5" aria-hidden />
                </button>
              </div>
            </div>
          </div>
        </nav>
        <div className="bg-black mt-4">
          <div className="container mx-auto container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="w-full flex items-center overflow-x-auto sm:overflow-hidden lg:justify-center">
              {!runId ? (
                <Link
                  to="/"
                  className="font-univers-condensed-bold uppercase"
                  onClick={() => setActiveUrl('Input')}
                >
                  <div className="bg-yellow text-center py-2 px-4 sm:px-6 lg:px-8">
                    Input
                  </div>
                </Link>
              ) : (
                navigationItems.map((navigationItem) => {
                  return (
                    <NavigationItem
                      key={navigationItem.title}
                      navigationItem={navigationItem}
                      setActiveUrl={setActiveUrl}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </header>
      <div className="spacer mb-8 sm:mb-12"></div>
    </>
  );
}

/**
 * The header navigation item component
 *
 * @param {Object} navigationItem // The navigation item
 * @return {JSX.Element}
 */
export function NavigationItem({ navigationItem, setActiveUrl }) {
  return (
    <Link
      to={navigationItem.url}
      className="font-univers-condensed-bold uppercase"
      onClick={() => setActiveUrl(navigationItem.title)}
    >
      <div
        className={`${
          navigationItem.active ? 'bg-yellow' : 'text-white hover:text-yellow'
        } text-center py-2 px-4 sm:px-6 lg:px-8`}
      >
        {navigationItem.title}
      </div>
    </Link>
  );
}
