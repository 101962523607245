import { colorPicker } from './ColorPicker';
import { notNeededKeys } from '../Constants';
import { formatNumber } from '.';
/**
 * Add a custom tooltip modal
 *
 * @param {boolean} active // Whether the tooltip is active or not
 * @param {array} payload // The array of objects which are part of the graph
 * @param {string} label // The label of the graph
 * @param {string} period // The selected period
 *
 * @return {element} // an element which shows the values on hover
 */
export const CustomTooltip = ({ active, payload, label, period }) => {
  // This to check whether the period is one of below 2 options to show kW or kWh
  const unitCheck = period === 'Day-hours' || period === 'Day' ? true : false;

  // Load profile is not beeing used in multiple places,
  // Here it does need to be shown in the on hover
  const notNeededKeysMinusLoad = notNeededKeys.filter(
    (el) => el !== 'Load Profile'
  );

  if (active && payload && payload.length) {
    const unitCheckBig = unitCheck ? 'MW' : 'MWh';
    const unitCheckSmall = unitCheck ? 'kW' : 'kWh';
    return (
      <div className="custom-tooltip shadow-micro bg-background rounded-lg py-6 px-4 space-y-6 border-2 border-gray200">
        <div className="font-univers-condensed-bold">{label ? label : ''}</div>
        {payload.map((element) =>
          element.value !== 0 &&
          !notNeededKeysMinusLoad.includes(element.name) ? (
            <div className="flex" key={element.name}>
              <p
                key={`${element.name}`}
                style={{ color: `${colorPicker(element.name)}` }}
                className="label-name"
              >{`${element.name}: `}</p>
              <p className="label-value">
                {`${
                  element.value > 9999
                    ? `${formatNumber(
                        Math.round(element.value / 1000)
                      )} ${unitCheckBig}`
                    : `${formatNumber(
                        Math.round(element.value)
                      )} ${unitCheckSmall}`
                }`}
              </p>
            </div>
          ) : null
        )}
      </div>
    );
  }

  return null;
};

/**
 * Add a custom tooltip modal
 *
 * @param {boolean} active // Whether the tooltip is active or not
 * @param {array} payload // The array of objects which are part of the graph
 * @param {string} label // The label of the graph
 *
 * @return {element} // an element which shows the values on hover
 */
export const CustomTooltipPercentage = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip shadow-micro bg-background rounded-lg py-6 px-4 space-y-6 border-2 border-gray200">
        <div className="font-univers-condensed-bold">{label ? label : ''}</div>
        {payload.map((element) =>
          element.value !== 0 && !notNeededKeys.includes(element.name) ? (
            <div className="flex" key={element.name}>
              <p
                key={`${element.name}`}
                style={{ color: `${colorPicker(element.name)}` }}
                className="label-name"
              >{`${element.name} : `}</p>
              <p className="label-value">
                {element.name !== 'Max SoC'
                  ? `${Math.round(element.value)} %`
                  : `${Math.round(
                      element.payload['Used range'] + element.payload['Min SoC']
                    )} %`}
              </p>
            </div>
          ) : null
        )}
      </div>
    );
  }

  return null;
};

/**
 * Add a custom tooltip modal
 *
 * @param {boolean} active // Whether the tooltip is active or not
 * @param {array} payload // The array of objects which are part of the graph
 * @param {string} label // The label of the graph
 *
 * @return {element} // an element which shows the values on hover
 */
export const CustomTooltipPiechart = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip shadow-micro bg-background rounded-lg py-6 px-4 space-y-6 border-2 border-gray200">
        <div className="font-univers-condensed-bold">{label ? label : ''}</div>
        {payload.map((element) =>
          element.value !== 0 && !notNeededKeys.includes(element.name) ? (
            <div className="flex">
              <p
                key={`${element.dataKey}`}
                style={{ color: `${colorPicker(element.payload.label)}` }}
                className="label-name"
              >{`${element.payload.label} : `}</p>
              <p className="label-value">
                {`${Math.round(element.payload.value * 100)} %`}
              </p>
            </div>
          ) : null
        )}
      </div>
    );
  }

  return null;
};

/**
 * Add a custom tooltip modal for the genset util chart
 *
 * @param {boolean} active // Whether the tooltip is active or not
 * @param {array} payload // The array of objects which are part of the graph
 * @param {string} label // The label of the graph
 *
 * @return {element} // an element which shows the values on hover
 */
export const CustomTooltipPercentageGensetUtil = ({
  active,
  payload,
  label,
}) => {
  /**
   * Below calculation is provided by datalab. This will show a different number on the tooltip compared to the graph itself
   * @param {string} name // The name of the element
   * @param {array} payload // The array of objects which are part of the graph
   *
   * @return {number} // a number for the label
   */
  function valueReturner(name, payload) {
    switch (name) {
      case 'mean':
        return Math.round(payload['mean']);
      case 'max':
        return Math.round(payload['max']) + Math.round(payload['mean']);
      default:
        return Math.round(payload['unused']);
    }
  }
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip shadow-micro bg-background rounded-lg py-6 px-4 space-y-6 border-2 border-gray200">
        <div className="font-univers-condensed-bold">{label ? label : ''}</div>
        {payload.map((element) =>
          element.value !== 0 && !notNeededKeys.includes(element.name) ? (
            <div className="flex" key={element.name}>
              <p
                key={`${element.name}`}
                style={{ color: `${colorPicker(element.name)}` }}
                className="label-name"
              >{`${element.name} : `}</p>
              <p className="pl-1">
                {valueReturner(element.name, element.payload)}
              </p>
            </div>
          ) : null
        )}
      </div>
    );
  }
  return null;
};
