import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import Header from "./Header";
import Footer from "./Footer";
import Notification from "./Notification";

/**
 * The basic layout
 *
 * @return {JSX.Element}
 */
const Layout = ({ children }) => {
  const { authState } = useOktaAuth();

  return (
    <>
      <Header />
      <Notification/>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-10">
        <div className="lg:grid lg:grid-cols-12">
          {authState?.isAuthenticated && children}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
