import {
  batteryCapacityType,
  batteryNameType,
  beginDateType,
  efficiencyType,
  endDateType,
  generatorType,
  gridPowerExportType,
  gridPowerImportType,
  gridToBatType,
  hourlyDemandPrefilledType,
  hourlyDemandType,
  initialScenario,
  purchasingPriceType,
  runIdType,
  scenarioType,
  sellingPriceType,
  surfaceAreaType,
} from '../Constants';

export const initialInputData = {
  run_id: '',
  demand: {
    load_profile_timeframe_minutes: 0,
    hourly_demand: {},
    customer: '',
    order: {},
    id: '',
  },
  grid: {
    max_grid_import: 0,
    max_grid_export: 0,
    price_per_kwh_export: 0,
    price_per_kwh_import: 0,
    id: '',
  },
  generators: [],
  battery: {
    name: '',
    capacity: 0,
    inverter_capacity: 0,
    min_soc: 0,
    dcac_efficiency: 100,
    max_soc: 0,
    price: 0,
    id: '',
  },
  solar: {
    surface_area: 0,
    efficiency: 0,
    id: '',
  },
  id: '',
  scenarios_per_timeframe: [initialScenario],
};

/**
 * Update the inputData reducer
 *
 * @param {object} state // Current state
 * @param {object} action // The action
 *
 * @return {object}
 */
export function updateInputData(state, action) {
  switch (action.type) {
    case hourlyDemandType:
      return {
        ...state,
        demand: {
          ...state?.demand,
          hourly_demand: {
            ...state?.demand?.hourly_demand,
            [action.key]: action.value,
          },
        },
      };
    case surfaceAreaType:
      return {
        ...state,
        solar: {
          ...state?.solar,
          surface_area: Number(action.value),
        },
      };
    case efficiencyType:
      return {
        ...state,
        solar: {
          ...state?.solar,
          efficiency: Number(action.value),
        },
      };
    case batteryNameType:
      return {
        ...state,
        battery: action.value,
      };
    case batteryCapacityType:
      return {
        ...state,
        battery: {
          ...state?.battery,
          capacity: action.value,
        },
      };
    case gridPowerImportType:
      return {
        ...state,
        grid: {
          ...state?.grid,
          max_grid_import: Number(action.value),
        },
      };
    case gridPowerExportType:
      return {
        ...state,
        grid: {
          ...state?.grid,
          max_grid_export: Number(action.value),
        },
      };
    case generatorType:
      return {
        ...state,
        generators: action.value,
      };
    case purchasingPriceType:
      return {
        ...state,
        grid: {
          ...state?.grid,
          price_per_kwh_import: Number(action.value),
        },
      };
    case sellingPriceType:
      return {
        ...state,
        grid: {
          ...state?.grid,
          price_per_kwh_export: Number(action.value),
        },
      };
    case scenarioType:
      return {
        ...state,
        scenarios_per_timeframe: action.value,
      };
    case beginDateType:
      return {
        ...state,
        scenarios_per_timeframe: [
          {
            ...state.scenarios_per_timeframe[0],
            begin_date: action.value,
          },
        ],
      };
    case endDateType:
      return {
        ...state,
        scenarios_per_timeframe: [
          {
            ...state.scenarios_per_timeframe[0],
            end_date: action.value,
          },
        ],
      };
    case gridToBatType:
      return {
        ...state,
        scenarios_per_timeframe: [
          {
            ...state.scenarios_per_timeframe[0],
            grid_to_bat: action.value,
          },
        ],
      };
    case runIdType:
      return {
        ...state,
        run_id: action.value,
      };
    case hourlyDemandPrefilledType:
      return {
        ...state,
        demand: {
          hourly_demand: action.value ?? {},
        },
      };
    default:
      return state;
  }
}

/**
 * Prefill the input data based on the previous run ID.
 *
 * @param {string}   runId           // Run ID of the shared link.
 * @param {object}   previousData    // The previous config data.
 * @param {function} updateInputData // Update the input data.
 * @param {function} setLoadProfile  // Update the load profile.
 * @param {function} setSolarProfile // Update the solar profile.
 */
export async function prefillInputData(
  runId,
  previousData,
  updateInputData,
  setLoadProfile,
  setSolarProfile
) {
  // Create a mapping for the data we have to update.
  const dataMappings = [
    { type: runIdType, value: runId },
    { type: gridPowerImportType, value: previousData?.grid?.max_grid_import },
    { type: gridPowerExportType, value: previousData?.grid?.max_grid_export },
    {
      type: purchasingPriceType,
      value: previousData?.grid?.price_per_kwh_import,
    },
    { type: sellingPriceType, value: previousData?.grid?.price_per_kwh_export },
    { type: generatorType, value: previousData?.generators },
    { type: batteryNameType, value: previousData?.battery },
    { type: scenarioType, value: previousData?.scenarios_per_timeframe },
  ];

  dataMappings.forEach((mapping) => {
    updateInputData(mapping);
  });

  if (previousData?.demand?.provided_load_profile) {
    setLoadProfile({ name: previousData?.demand?.load_filename });
  } else {
    updateInputData({
      type: hourlyDemandPrefilledType,
      value: previousData?.demand?.hourly_demand,
    });
  }
  if (previousData?.solar?.provided_solar_profile) {
    setSolarProfile({ name: previousData?.solar?.solar_filename });
  } else {
    updateInputData({
      type: surfaceAreaType,
      value: previousData?.solar?.surface_area,
    });
    updateInputData({
      type: efficiencyType,
      value: previousData?.solar?.efficiency,
    });
  }
}

/**
 * Open the file upload window
 *
 * @param {string} elementId // The element ID
 */
export function openFileUpload(elementId) {
  document.getElementById(elementId).click();
}
