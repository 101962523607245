import { useEffect, useState } from 'react';
import { getKpiData } from '../services/kpiDataService';

/**
 * The handeling of api data
 *
 * @param {object} runId     // The run ID of the current run
 *
 * @return {Promise}
 */
export function useKpiData(runId) {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = () => {
      // reset error state
      setHasError(false);
      setError('');
      setDataLoading(true);
      getKpiData(runId)
        .then((res) => {
          // Make sure the response contains an array.
          if (Array.isArray(res)) {
            setData(res);
          } else {
            setHasError(true);
            setError('Unexpected response from API');
          }
        })
        .catch((err) => {
          console.warn(err);
          setHasError(true);
          setError(err.message);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };

    fetchData();
  }, [runId]);

  return { data, dataLoading, hasError, error };
}
