import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import DownloadChartButton from '../Buttons/DownloadChartButton';
import { theme } from '../../tailwind.config';
import useInputContext from '../../hooks/useInputContext';
import { useChartData } from '../../hooks/useChartData';
import Loading from '../general/Loading';
import { colorPicker } from '../../helpers/ColorPicker';
import { notNeededKeys, periodDay, periodDayHours } from '../../Constants';
import { CustomTooltip } from '../../helpers/CustomizedTooltip';

const CustomizedAxisTick = (props) => {
  const { x, y, payload, period } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor="end" fill="#666">
        {payload.value > 9999
          ? `${payload.value / 1000} ${period === periodDayHours || period === periodDay ? 'MW' : 'MWh'}`
          : `${payload.value} ${period === periodDayHours || period === periodDay ? 'kW' : 'kWh'}`}
      </text>
    </g>
  );
};

/**
 * The PowerTimeChart component
 *
 * @return {JSX.Element}
 */
export default function PowerTimeChart({ period, startDate }) {
  const chartName = 'Power Over Time';
  const { runId } = useInputContext();
  const { data, dataLoading, hasError, error } = useChartData(
    runId,
    'power_time',
    period,
    startDate
  );

  /**
   * Makes an array of data to alter the rectangle to a square in the legend.
   * Load profile is already in the legend as it is also used in the <Bar/> Component
   * and it cannot be filtered out
   *
   * @return {array}
   */
  function payload() {
    if (data.length) {
      // Always add Load Profile to the legend.
      const payloadDataArray = [
        {
          id: 'Load Profile',
          value: 'Load Profile',
          type: 'line',
          color: theme.colors['micro-load-profile'],
        },
      ];

      data.forEach((dataObject) => {
        Object.keys(dataObject)
          .filter((value) => !notNeededKeys.includes(value))
          .forEach((element) => {
            // We only add an item to the legend if there is data available...
            // and if we haven't added the item to the legend yet.
            if (
              dataObject[element] !== 0 &&
              !payloadDataArray.some((e) => e.id === element)
            ) {
              payloadDataArray.push({
                id: element,
                value: element,
                type: 'square',
                color: colorPicker(element),
              });
            }
          });
      });

      return payloadDataArray;
    }
  }
  const elements = document.querySelector('#pot-chart');

  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        <div className="chart-container-POT">
          <div
            id="button-wrapper"
            className="flex items-center justify-between pb-8"
          >
            <h2 className="text-xl uppercase">{chartName}</h2>
            <DownloadChartButton
              isLoading={dataLoading}
              name={chartName}
              elements={elements}
            />
          </div>
          {hasError ? (
            <p>{error}</p>
          ) : (
            <ResponsiveContainer height={500} width="100%">
              <ComposedChart
                data={data}
                margin={{
                  top: 20,
                  right: 0,
                  left: 0,
                  bottom: 30,
                }}
              >
                <CartesianGrid strokeDasharray="3 0" vertical={false} />
                <XAxis dataKey="label" axisLine={false} tickLine={false} />
                <YAxis
                  width={80}
                  axisLine={false}
                  tickLine={false}
                  tick={<CustomizedAxisTick period={period} />}
                />
                <Tooltip content={<CustomTooltip period={period} />} />
                <Legend
                  align="right"
                  verticalAlign="bottom"
                  layout="horizontal"
                  payload={payload()}
                />

                {data.length &&
                  Object.keys(data[0])
                    .filter((value) => !notNeededKeys.includes(value))
                    .map((keyName) => {
                      return (
                        <Bar
                          key={keyName}
                          dataKey={keyName}
                          stackId="a"
                          fill={colorPicker(keyName)}
                        />
                      );
                    })}
                <Line
                  type="step"
                  dataKey="Load Profile"
                  stroke={theme.colors['micro-load-profile']}
                  strokeWidth={3}
                  strokeDasharray="6 6"
                  dot={false}
                />
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </div>
      )}
    </>
  );
}
