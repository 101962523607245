/**
 * Asset input field
 *
 * @return {JSX.Element}
 */
export default function AssetInputField({inputField, setAsset}) {
  return (
    <div key={inputField.label} className="col-span-6 sm:col-span-2">
      <label htmlFor={inputField.name} className="block text-sm font-medium">
        {inputField.label}
      </label>
      <input
        defaultValue={inputField.value}
        onChange={(e) =>
          setAsset({ type: inputField.name, value: e.target.value })
        }
        type={inputField.inputType}
        name={inputField.name}
        id={inputField.name}
        className="mt-1 block w-full border border border-gray200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
  );
}
