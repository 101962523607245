import { CheckIcon } from "@heroicons/react/outline";
import React from "react";

import { classNames } from "../../helpers/ClassNames";

/**
 * The navigation component
 *
 * @return {JSX.Element}
 */
const Navigation = ({ navigation, tab, setTab }) => {
  return (
    <aside className='pb-6 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2'>
      <nav className='space-y-3'>
        {navigation.map((item, i) => (
          <button
            key={item.name}
            className={classNames(
              item.name === tab ? "shadow bg-yellow" : "bg-white",
              "w-full group px-3 py-3 flex items-center text-lg font-medium lg:rounded-md lg:rounded-r-none"
            )}
            onClick={() => setTab(item.name)}
          >
            <div className="rounded-full bg-black text-white text-sm mr-2 font-univers-condensed-bold flex items-center justify-center w-5 h-5">
              <div>
                {item.checked ?
                  <CheckIcon className="w-4 h-4"/>
                  :
                  i + 1
                }
              </div>
            </div>
            <span className='truncate text-sm font-univers-condensed-bold uppercase'>
              {item.name}
            </span>
          </button>
        ))}
      </nav>
    </aside>
  );
};

export default Navigation;
