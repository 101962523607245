import { getDataFromApi } from '../dao/api';

/**
 * Get the chart data for a specific chart.
 *
 * @param {string} runId     // The ID of the current input
 *
 * @return {array} // The chart data
 */
export async function getKpiData(runId) {
  return await getDataFromApi(`/tool/kpis?run_id=${runId}`).then((res) => {
    return res.json();
  });
}
