import React from "react";
import { ArrowRightIcon, UploadIcon } from "@heroicons/react/outline";

import { efficiencyType, notificationTypeSuccess, surfaceAreaType } from "../../Constants";
import { openFileUpload } from "../../helpers/InputData";
import useNotificationContext from "../../hooks/useNotificationContext";
import useInputContext from "../../hooks/useInputContext";
import { Button } from '../Buttons/Button'

const INPUT_FIELD_ID = 'solarProfileUpload';

/**
 * The 'Solar profile' tab
 *
 * @return {JSX.Element}
 */
const SolarProfileTab = ({ tab, setTab }) => {
  const { setType, setNotification } = useNotificationContext();
  const { inputData, setInputData, solarProfile, setSolarProfile } =
    useInputContext();

  /**
   * Set the tab and file state.
   *
   * @param {file} file // The uploaded file
   */
  function uploadSolarProfile(file) {
    setSolarProfile(file);
    setTab('Variable input');
    setType(notificationTypeSuccess);
    setNotification({
      title: 'Success',
      description: 'The solar profile is successfully uploaded',
    });
  }

  /**
   * Delete the current solar profile file.
   */
  function deleteSolarProfile() {
    // Remove value in input.
    document.getElementById(INPUT_FIELD_ID).value = null;

    setSolarProfile();
    setType(notificationTypeSuccess);
    setNotification({
      title: 'Success',
      description: 'The solar profile is successfully removed',
    });
  }

  return (
    <div className={'Solar Profile' === tab ? '' : 'hidden'}>
      <div className="shadow bg-white rounded-r-lg rounded-bl-lg py-6 px-4 space-y-6 sm:p-6 mb-6">
        <div>
          <div className="flex items-end justify-between w-100">
            <div>
              <h1 className="text-2xl leading-6 font-medium pb-2 uppercase">
                Solar Profile
              </h1>
              <p className="mt-1 text-sm font-univers-light">
                Please upload your data or fill in your solar profile
              </p>
            </div>
            <input
              type="file"
              id={INPUT_FIELD_ID}
              onChange={(e) => uploadSolarProfile(e.target.files[0])}
              accept=".csv"
              hidden
            />
            <div className="flex items-center">
              {solarProfile && <p className="mr-4">{solarProfile.name}</p>}
              {!inputData?.run_id && solarProfile &&
                <Button
                  variant="secondary"
                  onClick={() => deleteSolarProfile()}
                >
                  Remove solar profile
                </Button>
              }
              {!inputData?.run_id && !solarProfile &&
                <button
                  onClick={() => openFileUpload(INPUT_FIELD_ID)}
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium text-white bg-black hover:bg-yellow focus:outline-none"
                >
                  <span className="mr-2 hidden sm:inline font-bold text-sm uppercase">
                    Upload solar profile
                  </span>
                  <UploadIcon className="w-5" aria-hidden="true" />
                </button>
              }
            </div>
          </div>
        </div>

        {!solarProfile && (
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-2">
              <label
                htmlFor="surface-area"
                className="block text-sm font-medium"
              >
                Surface area in m2
              </label>
              <input
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                type="number"
                id="surface-area"
                name="surface-area"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-md"
                onChange={(e) =>
                  setInputData({ type: surfaceAreaType, value: e.target.value })
                }
                value={
                  inputData?.solar?.surface_area
                    ? inputData?.solar?.surface_area
                    : ''
                }
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <label htmlFor="efficiency" className="block text-sm font-medium">
                Efficiency in %
              </label>
              <input
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                type="number"
                max="100"
                id="efficiency"
                name="efficiency"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-md"
                onChange={(e) =>
                  setInputData({ type: efficiencyType, value: e.target.value })
                }
                value={
                  inputData?.solar?.efficiency
                    ? inputData?.solar?.efficiency
                    : ''
                }
              />
            </div>
          </div>
        )}

        <div className="flex justify-end">
          <Button
            onClick={(e) => {
              e.preventDefault();
              setTab('Variable input');
            }}
          >
            Variable Input
            <ArrowRightIcon className="h-5 ml-3" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SolarProfileTab;
