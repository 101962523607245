/**
 * Update the generator reducer
 *
 * @param {object} state // Current state
 * @param {object} action // The action
 *
 * @return {object}
 */
export function setGeneratorObject(state, action) {
  switch (action.type) {
    case 'generator-type':
      return {
        ...state,
        type: action.value,
      };
    case 'purchase-price':
      return {
        ...state,
        price: action.value,
      };
    case 'operational-cost':
      return {
        ...state,
        operational_cost: action.value,
      };
    case 'emmission-co2':
      return {
        ...state,
        emmission_co2: action.value,
      };
    case 'emmission-nox':
      return {
        ...state,
        emmission_NOx: action.value,
      };
    case 'power':
      return {
        ...state,
        capacity: action.value,
      };
    case 'load-ratio':
      return {
        ...state,
        min_power_perc: action.value,
      };
    default:
      return state;
  }
}

/**
 * Update the battery reducer
 *
 * @param {object} state // Current state
 * @param {object} action // The action
 *
 * @return {object}
 */
export function setBatteryObject(state, action) {
  switch (action.type) {
    case 'battery-name':
      return {
        ...state,
        name: action.value,
      };
    case 'purchase-price':
      return {
        ...state,
        price: action.value,
      };
    case 'power':
      return {
        ...state,
        capacity: action.value,
      };
    case 'inverter':
      return {
        ...state,
        inverter_capacity: action.value,
      };
    case 'minimal-soc':
      return {
        ...state,
        min_soc: action.value,
      };
    case 'maximum-soc':
      return {
        ...state,
        max_soc: action.value,
      };
    case 'discharge-factor':
      return {
        ...state,
        dcac_efficiency: action.value,
      };
    default:
      return state;
  }
}
