import { useEffect, useState } from 'react';
import { getBatteryData, getGeneratorData } from '../services/inputDataService';

/**
 * The handeling of api data
 *
 * @param {string} type // The type of asset to be used
 * @return {Promise}
 */
export function useAssets(type) {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const assets = () => {
      if (type === "generator") {
        return getGeneratorData();
      }
      if (type === "battery") {
        return getBatteryData();
      }
    };

    const fetchData = () => {
      setDataLoading(true);
      assets()
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          console.warn(err);
          setHasError(true);
          setError(err.message);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchData();
  }, [type]);

  return { data, dataLoading, hasError, error };
}
