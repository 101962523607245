import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// Sentry.
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_ENVIRONMENT } from "./Constants";

// Only init Sentry if we're in production or development
if (["DEV", "TEST", "PROD"].includes(SENTRY_ENVIRONMENT)) {
  Sentry.init({
    dsn: "https://9111a7585fe946069ef35ba16c2a0dbf@o230677.ingest.sentry.io/4503885470957568",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: SENTRY_ENVIRONMENT,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
