import { useEffect, useState } from 'react';
import { getChartData } from '../services/chartDataService';

/**
 * The handeling of api data
 *
 * @param {object} runId     // The run ID of the current run
 * @param {object} endpoint  // The endpoint to use
 * @param {string} period    // The selected period to use
 * @param {string} startDate // The selected startDate to pass
 *
 * @return {Promise}
 */
export function useChartData(runId, endpoint, period = '', startDate = '') {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = () => {
      // reset error state
      setHasError(false);
      setError('');
      setDataLoading(true);
      getChartData(runId, endpoint, period, startDate)
        .then((res) => {
          // Make sure the response contains an array.
          if (Array.isArray(res)) {
            setData(res);
          } else {
            setHasError(true);

            if (res?.detail) {
              setError(res.detail);
            } else {
              setError('Unexpected response from API');
            }
          }
        })
        .catch((err) => {
          console.warn(err);
          setHasError(true);
          setError(err.message);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };

    fetchData();
  }, [runId, endpoint, period, startDate]);

  return { data, dataLoading, hasError, error };
}
