/**
 * wait utility function
 * @param {number} ms delay
 * @return {promise}
 */
export const wait = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Format numbers
 * @param {number} number // Number to format.
 * @return {string}
 */
export const formatNumber = (number) => {
  return number.toLocaleString('nl-NL');
}
