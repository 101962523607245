import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useChartData } from '../../hooks/useChartData';
import { theme } from '../../tailwind.config';
import DownloadChartButton from '../Buttons/DownloadChartButton';
import useInputContext from '../../hooks/useInputContext';
import Loading from '../general/Loading';
import { CustomTooltip } from '../../helpers/CustomizedTooltip';

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    // x and y needed a little extra to align
    <g transform={`translate(${x + 25},${y + 15})`}>
      <text textAnchor="end" fill="#666">
        {payload.value > 9999
          ? `${payload.value / 1000} MWh`
          : `${payload.value} kWh`}
      </text>
    </g>
  );
};

/**
 * The BatterySoc component
 *
 * @return {JSX.Element}
 */
export default function PowerBalance() {
  const { runId } = useInputContext();
  const { data, dataLoading, hasError, error } = useChartData(
    runId,
    'power_balance'
  );
  const chartName = 'Power Balance';
  const elements = document.querySelector('#powerbalance-chart');

  return (
    <>
      <div className="chart-container power-balance">
        <div className="flex items-center justify-between pb-8">
          <h2 className="text-xl uppercase">{chartName}</h2>
          {!dataLoading && !!data.length && (
            <DownloadChartButton
              isLoading={dataLoading}
              name={chartName}
              elements={elements}
            />
          )}
        </div>
        {dataLoading ? (
          <Loading />
        ) : hasError ? (
          <p>{error}</p>
        ) : (
          <ResponsiveContainer aspect={4 / 2}>
            <BarChart
              layout="vertical"
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 0,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                label={{ position: 'center' }}
                tick={<CustomizedAxisTick />}
              />
              <YAxis width={30} type="category" dataKey="name" hide={true} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar
                barSize={200}
                dataKey="Gas Generator"
                stackId="b"
                fill={theme.colors['micro-genset-used']}
              />
              <Bar
                barSize={200}
                dataKey="Diesel Generator"
                stackId="b"
                fill={theme.colors['micro-genset-curtailed']}
              />
              <Bar
                barSize={200}
                dataKey="Battery"
                stackId="b"
                fill={theme.colors['micro-battery-supply']}
              />
              <Bar
                barSize={200}
                dataKey="Grid"
                stackId="b"
                fill={theme.colors['micro-grid']}
              />
              <Bar
                barSize={200}
                dataKey="Solar Used"
                stackId="b"
                fill={theme.colors['micro-solar']}
              />
              <Bar
                barSize={200}
                dataKey="Power Shortage"
                stackId="b"
                fill={theme.colors['micro-shortage']}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
}
