import { DownloadIcon } from '@heroicons/react/outline';
import { makePNGFromHTMLRef } from '../../helpers/Makepng';

/**
 * This is the component for the download button of the graph
 *
 * @param {boolean} isLoading // The name of the chart.
 * @param {string} name // The name of the chart.
 * @param {html} elements // The collection of html elements to take a screenshot of
 *
 * @return {JSX.Element}
 */
export default function DownloadChartButton({ isLoading, name, elements }) {
  return (
    <button
      className="download-chart-button flex px-3 py-2 items-center bg-yellow"
      disabled={isLoading}
      onClick={() => makePNGFromHTMLRef(elements, isLoading, name)}
    >
      {isLoading ? (
        <span className="download-button-text mr-1 font-bold text-sm uppercase">
          Downloading...
        </span>
      ) : (
        <span className="download-button-text mr-1 font-bold text-sm uppercase">
          Download {name} chart
        </span>
      )}
      <DownloadIcon className="flex-shrink-0 h-4 w-4" />
    </button>
  );
}
