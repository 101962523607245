import React, { useEffect, useState } from 'react';
import { notificationTypeError, TIME_TO_READ } from "../Constants";

// Create the context
const NotificationContext = React.createContext();

const initialNotification = {
  title : '',
  description: ''
}

// This provider is intended to be surrounding the whole application.
export const NotificationProvider = ({ children }) => {
  const [type, setType] = useState(notificationTypeError);
  const [notification, setNotification] = useState(initialNotification);

  useEffect(() => {
    if (notification.title !== '') {
      const timeout = setTimeout(() => {
        setNotification(initialNotification);
      }, TIME_TO_READ);

      return () => clearTimeout(timeout);
    }
  }, [notification.title]);

  // This component will render its children
  // wrapped around a NotificationContext's provider whose
  // value is set to the method defined above
  return (
    <NotificationContext.Provider
      value={{
        notification: notification,
        setNotification: setNotification,
        type: type,
        setType: setType,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
