import BatterySocBarChart from '../components/Charts/BatterySocBarChart';
import PowerTimeChart from '../components/Charts/PowerTimeChart';
import TabContent from '../components/general/TabContent';
import DatePickerFilter from '../components/general/DatePickerFilter';
import { useEffect, useState } from 'react';
import KpiBlock from '../components/general/KpiBlock';
import { useKpiData } from '../hooks/useKpiData';
import useInputContext from '../hooks/useInputContext';
import Loading from '../components/general/Loading';
import { formatNumber } from '../helpers';
import { useFilters } from '../hooks/useFilters';

/**
 * The overview page
 *
 * @param {string} props // Component properties
 * @return {JSX.Element}
 */
export default function Overview() {
  const { runId } = useInputContext();
  const { data, dataLoading, hasError, error } = useKpiData(runId);
  const { data: filterData, dataLoading: filterDataLoading } =
    useFilters(runId);
  const [startDate, setStartDate] = useState(
    filterData?.startDate ? filterData?.startDate : ''
  );
  const [period, setPeriod] = useState('Year');

  useEffect(() => {
    if (filterData?.startDate) {
      setStartDate(filterData.startDate);
    }
  }, [filterData.startDate]);

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12">
        <TabContent
          title="Overview"
          content="Demonstration of the operation of microgrid power components per (year, month and day)."
        />
        {!filterDataLoading && filterData?.startDate && filterData?.endDate && (
          <DatePickerFilter
            minDate={filterData?.startDate}
            maxDate={filterData?.endDate}
            setStartDate={setStartDate}
            period={period}
            setPeriod={setPeriod}
            quartersAvailable={filterData?.period === 'quarters' ? true : false}
          />
        )}
      </div>
      {startDate && (
        <>
          <section
            id="pot-chart"
            className="shadow-micro bg-white rounded-lg py-6 px-4 space-y-6 sm:p-10 mb-6"
          >
            <PowerTimeChart startDate={startDate} period={period} />
          </section>
          <section
            id="batterySOC-chart"
            className="shadow-micro bg-white rounded-lg py-6 px-4 space-y-6 sm:p-10 mb-6"
          >
            <BatterySocBarChart startDate={startDate} period={period} />
          </section>
        </>
      )}
      <section className="mb-6">
        {dataLoading ? (
          <section className="shadow-micro bg-white rounded-lg py-6 px-4 space-y-6 sm:p-10 mb-6">
            <Loading />
          </section>
        ) : hasError ? (
          <p>{error}</p>
        ) : (
          data.length && (
            <>
              <div className="grid md:grid-cols-4 gap-4 mb-6">
                <KpiBlock
                  title="Total Emissions (generator)"
                  content={`${formatNumber(data[0].emission.nox)} nox`}
                  subContent={`${formatNumber(data[0].emission.co2)} CO2`}
                />
                <KpiBlock
                  title="Total Investment"
                  content={`€${formatNumber(data[0].total_investment)},-`}
                />
                <KpiBlock
                  title="Total Consumption (generator)"
                  content={`${formatNumber(data[0].operational_costs.kwh)} kWh`}
                  subcontent={`${formatNumber(
                    data[0].operational_costs.euro
                  )},-`}
                />
                <KpiBlock
                  title="Power sold to grid"
                  content={`${formatNumber(data[0].grid_export.kwh)} kWh`}
                  subContent={`€${formatNumber(data[0].grid_export.euro)},-`}
                />
              </div>
              <div className="grid md:grid-cols-3 gap-4">
                <KpiBlock
                  title="Fuel saved"
                  content={`${formatNumber(data[0].fuel_saved)} Litres`}
                />
                <KpiBlock
                  title="Purchased Grid Power"
                  content={`${formatNumber(data[0].grid_import.kwh)} kWh`}
                  subContent={`€${formatNumber(data[0].grid_import.euro)},- `}
                />
                <KpiBlock
                  title="Reliability"
                  content={`${formatNumber(data[0].reliability)} Blackouts`}
                />
              </div>
            </>
          )
        )}
      </section>
    </div>
  );
}
