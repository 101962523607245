import React from 'react';
import { Button } from '../Buttons/Button';

export const GeneratorSelect = ({
  index,
  generator,
  generatorData,
  hasError,
  error,
  setGeneratorSet,
  removeGenerator,
}) => {

  // Update the generator input values.
  const updateGenerator = (value) => {
    setGeneratorSet((prevState) => {
      const newState = prevState.map((generator, key) => {
        if (key === index) {
          // Get the object of the selected generator.
          const newValue = generatorData.filter(genObj => genObj.type === value)[0];

          return newValue;
        }

        return generator;
      });
      return newState;
    });
  };

  return (
    <>
      <div className="col-span-6 sm:col-span-2">
        <label htmlFor="generator-type" className="block text-sm font-medium">
          Type of Generator
        </label>
        <select
          id="generator-type"
          name="generator-type"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-md"
          onChange={(e) => updateGenerator(e.target.value)}
          value={generator?.type ? generator?.type : ''}
        >
          <option value="">No generator</option>
          {generatorData.map((value) => (
            <option key={value.id} value={value.type}>
              {value.type}
            </option>
          ))}
        </select>
        {hasError && (
          <p
            className="col-span-6 text-sm font-small text-micro-shortage pt-2"
            value=""
          >
            {`Error: ${error}`}
          </p>
        )}
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label htmlFor="generator-type" className="block text-sm font-medium">
          Installed generators power in kW
        </label>
        <input
          id="generator-type"
          name="generator-type"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none border focus:border-black sm:text-sm rounded-md bg-gray100 border-gray200"
          disabled={true}
          value={generator?.capacity ? generator?.capacity : '0'}
        />
      </div>

      <div className="col-span-6 sm:col-span-2 flex items-end">
        <Button variant="secondary" onClick={(e) => removeGenerator(e, index)}>
          Remove generator
        </Button>
      </div>
    </>
  );
};
