import GensetRunningHours from '../components/Charts/GensetRunningHours';
import GensetUtilisation from '../components/Charts/GensetUtilisation';
import TabContent from '../components/general/TabContent';

/**
 * The generators page
 *
 * @return {JSX.Element}
 */
export default function Generators() {
  return (
    <div className="col-span-12">
      <TabContent
        title="Generators"
        content="Overview of the usage of the generator sets for the entire simulation period."
      />
      <section
        id="genset-running-chart"
        className="shadow-micro bg-white rounded-lg py-6 px-4 space-y-6 sm:p-10 mb-6"
      >
        <GensetRunningHours />
      </section>
      <section
        id="genset-util-chart"
        className="shadow-micro bg-white rounded-lg py-6 px-4 space-y-6 sm:p-10 mb-6"
      >
        <GensetUtilisation />
      </section>
    </div>
  );
}
