import "./App.css";
import Form from "./components/Forms/Form";
import Layout from "./components/general/Layout";
import { useCallback, useEffect, useRef } from "react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Route, Routes, useNavigate } from "react-router-dom";
import { oktaAuth } from "./auth/oktaAuth";
import { Security, LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./auth/RequiredAuth";
import Overview from "./pages/Overview";
import Power from "./pages/Power";
import Solar from "./pages/Solar";
import Grid from "./pages/Grid";
import Generators from "./pages/Generators";
import { InputProvider } from "./contexts/inputContext";
import { NotificationProvider } from "./contexts/notificationContext";
import Admin from "./pages/Admin";
import { NotFound } from "./components/Page/NotFound";

/**
 * This is the main entry-point into the app.
 *
 * @return {JSX.Element}
 */
function App() {
  // BEGIN: Temporary fix for duplicate call
  // https://github.com/okta/okta-react/issues/227
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const restoreOriginalUri = useCallback((_, originalUri) => {
    const url = toRelativeUrl(originalUri, "/");
    navigateRef.current(url);
  }, []);

  useEffect(() => {
    return () => {
      oktaAuth.options.restoreOriginalUri = undefined;
    };
  }, []);
  // END: Temporary fix for duplicate call

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <InputProvider>
        <NotificationProvider>
          <Routes>
            <Route path="/" element={<RequiredAuth />}>
              <Route
                index
                element={
                  <Layout>
                    <Form />
                  </Layout>
                }
              />
              <Route
                path="/overview"
                element={
                  <Layout>
                    <Overview/>
                  </Layout>
                }
              />
              <Route
                path="/power"
                element={
                  <Layout>
                    <Power/>
                  </Layout>
                }
              />
              <Route
                path="/solar"
                element={
                  <Layout>
                    <Solar/>
                  </Layout>
                }
              />
              <Route
                path="/grid"
                element={
                  <Layout>
                    <Grid/>
                  </Layout>
                }
              />
              <Route
                path="/generators"
                element={
                  <Layout>
                    <Generators/>
                  </Layout>
                }
              />
              <Route
                path="/admin-portal"
                element={
                  <Layout>
                    <Admin/>
                  </Layout>
                }
              />
              <Route
                path="*"
                element={
                  <Layout>
                    <NotFound/>
                  </Layout>
                }
              />
            </Route>
            <Route path="/login/callback" element={<LoginCallback />} />
          </Routes>
        </NotificationProvider>
      </InputProvider>
    </Security>
  );
}

export default App;
