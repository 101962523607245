/**
 * The footer component
 *
 * @return {JSX.Element}
 */
export default function Footer() {
  const copyrightYear = new Date().getFullYear();

  return (
    <footer className='mt-6 sm:mt-0 bg-yellow shadow'>
      <div
        className='container py-3 mx-auto px-4 md:px-6 lg:px-8'
        aria-label='Footer'
      >
        <div className='w-full py-3 lg:flex items-center justify-between'>
          <div>
            <ul className='block lg:flex'>
              <li>
                <p className="font-univers-condensed">
                  © {copyrightYear} Pon Cat All rights reserved
                </p>
              </li>
              <li>
                <a
                  href='https://www.pon-cat.com/en-nl/privacy-statement'
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  Privacy statement
                </a>
              </li>
              <li>
                <a
                  href='https://www.pon-cat.com/en-nl/terms-of-condition'
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  Terms of use
                </a>
              </li>
              <li>
                <a
                  href='https://www.pon-cat.com/en-nl/cookie-policy'
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  Cookies
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
