import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import DownloadChartButton from '../Buttons/DownloadChartButton';
import { theme } from '../../tailwind.config';
import useInputContext from '../../hooks/useInputContext';
import { useChartData } from '../../hooks/useChartData';
import Loading from '../general/Loading';
import { CustomTooltipPercentageGensetUtil } from '../../helpers/CustomizedTooltip';

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor="end" fill={theme.colors.black}>
        {payload.value} %
      </text>
    </g>
  );
};

/**
 * The PowerTimeChart component
 *
 * @return {JSX.Element}
 */
export default function GensetUtilisation() {
  const { runId } = useInputContext();
  const { data, dataLoading, hasError, error } = useChartData(
    runId,
    'genset_utilisation'
  );
  const chartName = 'Genset Utilisation';
  const elements = document.querySelector('#genset-util-chart');

  return (
    <>
      <div className="chart-container">
        <div className="flex items-center justify-between pb-8">
          <h2 className="text-xl uppercase">{chartName}</h2>
          {!dataLoading && !!data.length && (
            <DownloadChartButton
              isLoading={dataLoading}
              name={chartName}
              elements={elements}
            />
          )}
        </div>
        {dataLoading ? (
          <Loading />
        ) : hasError ? (
          <p>{error}</p>
        ) : (
          <ResponsiveContainer aspect={4 / 2}>
            <ComposedChart
              barCategoryGap={15}
              data={data}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 30,
              }}
            >
              <CartesianGrid strokeDasharray="3 0" vertical={false} />
              <XAxis dataKey="label" axisLine={false} tickLine={false} />
              <YAxis
                width={80}
                axisLine={false}
                tickLine={false}
                tick={<CustomizedAxisTick />}
                ticks={[0, 50, 100]}
              />
              <Tooltip content={<CustomTooltipPercentageGensetUtil />} />
              <Legend
                align="center"
                verticalAlign="bottom"
                layout="horizontal"
              />
              <Bar
                dataKey="mean"
                stackId="a"
                fill={theme.colors['micro-shortage']}
              />
              <Bar
                dataKey="max"
                stackId="a"
                fill={theme.colors['micro-generator-used']}
              />
              <Bar
                dataKey="unused"
                stackId="a"
                fill={theme.colors['micro-generator-not-used']}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
}
