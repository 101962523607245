import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { theme } from '../../tailwind.config';
import DownloadChartButton from '../Buttons/DownloadChartButton';
import useInputContext from '../../hooks/useInputContext';
import { useChartData } from '../../hooks/useChartData';
import Loading from '../general/Loading';
import { CustomTooltipPercentage } from '../../helpers/CustomizedTooltip';

/**
 * The BatterySoc component
 *
 * @param {string} period    // The selected period to use
 * @param {string} startDate // The selected startDate to pass
 *
 * @return {JSX.Element}
 */
export default function BatterySocBarChart({ period, startDate }) {
  const chartName = 'Battery State of Charge';
  const { runId } = useInputContext();
  const { data, dataLoading, hasError, error } = useChartData(
    runId,
    'battery_soc',
    period,
    startDate
  );

  const elements = document.querySelector('#batterySOC-chart');

  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        <div className="chart-container">
          <div className="flex items-center justify-between pb-8">
            <h2 className="text-xl uppercase">{chartName}</h2>
            <DownloadChartButton
              isLoading={dataLoading}
              name={chartName}
              elements={elements}
            />
          </div>
          {hasError ? (
            // if there is no battery selected the error message is:
            // Cannot read properties of undefined (reading 'json')
            // hence to change it to something more readable
            <p>{error.includes('json') ? 'No battery selected' : error}</p>
          ) : (
            <ResponsiveContainer aspect={4 / 2}>
              <BarChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 30,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis
                  label={{ value: '%', angle: -90, position: 'insideLeft' }}
                />
                <Tooltip content={<CustomTooltipPercentage />} />
                <Legend />
                <Bar
                  dataKey="Min SoC"
                  stackId="a"
                  fill={theme.colors['micro-min-soc']}
                />
                <Bar
                  dataKey="Used range"
                  stackId="a"
                  fill={theme.colors['micro-battery-supply']}
                />
                <Bar
                  dataKey="Max SoC"
                  stackId="a"
                  fill={theme.colors['micro-max-soc']}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      )}
    </>
  );
}
