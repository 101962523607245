import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';

/**
 * This functions makes and downloads a PNG of the chosen chart
 *
 * @param {html} ref // The collection of html elements to take a screenshot of.
 * @param {boolean} isLoading // Whether the app is loading or not.
 * @param {string} name // Whether the app is loading or not.
 *
 */
export function makePNGFromHTMLRef(ref, isLoading, name) {
  if (!isLoading) {
    if (ref && ref.getElementsByClassName('download-chart-button')) {
      const button = ref.getElementsByClassName('download-chart-button')[0];
      /* add JS here to align the block with the text in the downloaded file

        */
      button.style.display = 'none';

      html2canvas(ref).then(function (canvas) {
        const imgData = canvas.toDataURL('image/png');
        // This line uses the atob() function to decode the Base64-encoded data URI that represents the image.
        // The split() method is used to remove the prefix from the data URI, leaving only the Base64-encoded image data.
        // Eventhough atob() is deprecated it is still the only option for browser environments
        const byteString = atob(imgData.split(',')[1]);

        // This line creates a new ArrayBuffer object with a length equal to the length of the decoded image data.
        const ab = new ArrayBuffer(byteString.length);

        // This line creates a new Uint8Array view of the ArrayBuffer object, which allows us to access the individual bytes of the image data.
        const ia = new Uint8Array(ab);

        // This loop iterates over the decoded image data and copies each byte to the Uint8Array view.
        // The charCodeAt() method is used to get the numerical value of the character at the current index of the string,
        // which represents the byte value. The byte values are then stored in the Uint8Array view,
        // which can be used to create a new Blob object containing the binary image data.
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ia], { type: 'image/png' });
        FileSaver.saveAs(blob, `${name}.png`);
      });
      button.style.display = 'flex';
    }
  }
}
