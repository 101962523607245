import { useReducer, useState } from 'react';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/outline';
import useNotificationContext from '../../hooks/useNotificationContext';
import { notificationTypeError, notificationTypeSuccess } from '../../Constants';
import AssetInputField from './AssetInputField';
import { Button } from '../Buttons/Button'



/**
 * Manage asset component
 *
 * @return {JSX.Element}
 */
export default function ManageAsset({
  assetObject,
  setAssets,
  inputFields,
  setAssetObject,
  assetLabel,
  patchAsset,
  deleteAsset,
}) {
  const { setType, setNotification } = useNotificationContext();
  const [opened, setOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [asset, setAsset] = useReducer(
    setAssetObject,
    assetObject
  );

  /**
   * Update the asset.
   */
  function updateAsset() {
    patchAsset(asset.id, asset).then(() => {
      setType(notificationTypeSuccess);
      setNotification({
        title: 'Success',
        description: `The ${assetLabel} was successfully updated`,
      });
    }).catch((err) => {
      setType(notificationTypeError);
      setNotification({
        title: 'Error',
        description: err.message,
      });
    });
  }

  /**
   * Delete asset
   */
  function removeAsset() {
    deleteAsset(asset.id).then(() => {
      setType(notificationTypeSuccess);
      setNotification({
        title: 'Success',
        description: `The ${assetLabel} was successfully deleted`,
      });

      // Remove the deleted asset from the list.
      setAssets((prevState) => {
        const newState = [];
        prevState.forEach((prevAsset) => {
          if (prevAsset.id !== asset.id) {
            newState.push(prevAsset);
          }
        })

        return newState;
      });
    }).catch((err) => {
      setType(notificationTypeError);
      setNotification({
        title: 'Error',
        description: err.message,
      });
    });
  }

  return (
    <>
      <div className="flex cursor-pointer mb-4" onClick={() => setOpened(!opened)}>
        {opened ?
          <ChevronDownIcon className="w-5 mr-3" />
          :
          <ChevronRightIcon className="w-5 mr-3" />
        }
        <h2>{asset.type ? asset.type : asset.name}</h2>
      </div>
      <div className={`grid grid-cols-6 gap-6 ml-8 mb-8 ${opened ? '' : 'hidden'}`}>

        {inputFields.map((inputField) => (
          <AssetInputField key={inputField.name} inputField={inputField} setAsset={setAsset}/>
        ))}

        <div className="col-span-6 md:flex md:items-end">
          <div className="inline-grid md:grid-cols-2 md:gap-4">
            <Button onClick={() => updateAsset()}>Update {assetLabel}</Button>
            <Button
              variant="secondary"
              onClick={() => setDeleteModalOpened(true)}
            >
              Delete {assetLabel}
            </Button>
          </div>
        </div>
      </div>

      {deleteModalOpened && (
        // Modal
        <div className="bg-black bg-opacity-40 w-full h-full fixed top-0 left-0 z-100 mt-0">
          <div className="bg-white p-3 md:p-8 w-10/12 md:w-6/12 mt-64 mx-auto">
            <p>
              Are you sure you want to delete the {assetLabel} named "
              {assetLabel === "generator" ? asset.type : asset.name}"?
            </p>

            <div className="flex mt-8">
              <div className="inline-grid grid-cols-2 gap-4">
                <Button onClick={() => setDeleteModalOpened(false)}>
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    removeAsset();
                    setDeleteModalOpened(false);
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
