import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import DownloadChartButton from '../Buttons/DownloadChartButton';
import { theme } from '../../tailwind.config';
import useInputContext from '../../hooks/useInputContext';
import { useChartData } from '../../hooks/useChartData';
import Loading from '../general/Loading';
import { CustomTooltip } from '../../helpers/CustomizedTooltip';

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor="end" fill="#666">
        {payload.value > 9999
          ? `${payload.value / 1000} MWh`
          : `${payload.value} kWh`}
      </text>
    </g>
  );
};

/**
 * The AverageSolarProduction component
 *
 * @return {JSX.Element}
 */
export default function AverageSolarProduction() {
  const { runId } = useInputContext();
  const { data, dataLoading, hasError, error } = useChartData(
    runId,
    'average_solar_production'
  );
  const chartName = 'Estimated Solar Power';
  const elements = document.querySelector('#solarprod-chart');

  return (
    <>
      <div className="chart-container">
        <div className="flex items-center justify-between pb-8">
          <h2 className="text-xl uppercase text-">{chartName}</h2>
          {!dataLoading && !!data.length && (
            <DownloadChartButton
              isLoading={dataLoading}
              name={chartName}
              elements={elements}
            />
          )}
        </div>
        {dataLoading ? (
          <Loading />
        ) : hasError ? (
          <p>{error}</p>
        ) : (
          <ResponsiveContainer aspect={4 / 2}>
            <BarChart
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 30,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="label" />
              <YAxis
                label={{
                  angle: -90,
                  position: 'insideLeft',
                }}
                tick={<CustomizedAxisTick />}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey="solarProduction"
                fill={theme.colors['yellow']}
                name="Solar Production"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
}
