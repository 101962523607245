import React, { useReducer, useState } from 'react';
import { initialInputData, updateInputData } from '../helpers/InputData';

// Create the context
const InputContext = React.createContext();

// This provider is intended to be surrounding the whole application.
export const InputProvider = ({ children }) => {
  const [runId, setRunId] = useState('');
  const [inputData, setInputData] = useReducer(
    updateInputData,
    initialInputData
  );
  const [loadProfile, setLoadProfile] = useState();
  const [solarProfile, setSolarProfile] = useState();

  // This component will render its children
  // wrapped around a InputContext's provider whose
  // value is set to the method defined above
  return (
    <InputContext.Provider
      value={{
        runId: runId,
        setRunId: setRunId,
        inputData: inputData,
        setInputData: setInputData,
        updateInputData: updateInputData,
        loadProfile: loadProfile,
        setLoadProfile: setLoadProfile,
        solarProfile: solarProfile,
        setSolarProfile: setSolarProfile,
      }}
    >
      {children}
    </InputContext.Provider>
  );
};

export default InputContext;
