import React from 'react';
import { UploadIcon, ArrowRightIcon } from '@heroicons/react/outline';

import {
  hourlyDemandType,
  notificationTypeSuccess,
  timesAM,
  timesPM,
} from '../../Constants';
import { openFileUpload } from '../../helpers/InputData';
import useNotificationContext from '../../hooks/useNotificationContext';
import useInputContext from '../../hooks/useInputContext';
import { Button } from '../Buttons/Button';

const INPUT_FIELD_ID = 'loadProfileUpload';

/**
 * The 'Load profile' tab
 *
 * @return {JSX.Element}
 */
const LoadProfileTab = ({ tab, setTab }) => {
  const { setType, setNotification } = useNotificationContext();
  const { inputData, setInputData, loadProfile, setLoadProfile } =
    useInputContext();

  /**
   * Set the tab and file state.
   *
   * @param {file} file // The uploaded file
   */
  function uploadLoadProfile(file) {
    setLoadProfile(file);
    setTab('Solar Profile');
    setType(notificationTypeSuccess);
    setNotification({
      title: 'Success',
      description: 'The load profile is successfully uploaded',
    });
  }

  /**
   * Delete the current load profile file.
   */
  function deleteLoadProfile() {
    // Remove value in input.
    document.getElementById(INPUT_FIELD_ID).value = null;

    setLoadProfile(null);
    setType(notificationTypeSuccess);
    setNotification({
      title: 'Success',
      description: 'The load profile is successfully removed',
    });
  }

  return (
    <div
      className={"Load Profile" === tab ? "" : "hidden"}
    >
      <div className="shadow bg-white rounded-lg lg:rounded-tl-none py-6 px-4 space-y-6 sm:p-6 mb-6">
        <div className="flex items-end justify-between w-100">
          <div>
            <h1 className="text-2xl leading-6 font-medium pb-2 uppercase">
              Load Profile
            </h1>
            <p className="mt-1 text-sm font-univers-light">
              Please enter your load profile information in kW
            </p>
          </div>
          <input
            type="file"
            id={INPUT_FIELD_ID}
            onChange={(e) => uploadLoadProfile(e.target.files[0])}
            accept=".csv"
            hidden
          />
          <div className="flex items-center">
            {loadProfile && <p className="mr-4">{loadProfile.name}</p>}
            {!inputData?.run_id && loadProfile &&
              <Button variant="secondary" onClick={() => deleteLoadProfile()}>
                Remove load profile
              </Button>
            }
            {!inputData?.run_id && !loadProfile &&
              <button
                onClick={() => openFileUpload(INPUT_FIELD_ID)}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium text-white bg-black hover:bg-yellow focus:outline-none"
              >
                <span className="mr-2 hidden sm:inline font-bold text-sm uppercase">
                  Upload load profile
                </span>
                <UploadIcon className="w-5" aria-hidden="true" />
              </button>
            }
          </div>
        </div>

        {!loadProfile && (
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <h3 className="text-lg leading-6 font-medium">AM</h3>
            <div className="grid grid-cols-6 gap-4">
              {timesAM.map((item) => (
                <div key={item.key} className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor={item.key}
                    className="block text-sm font-medium"
                  >
                    {item.value} {loadProfile ? '' : '*'}
                  </label>
                  <input
                    onChange={(e) =>
                      setInputData({
                        type: hourlyDemandType,
                        key: item.key,
                        value: e.target.value,
                      })
                    }
                    type="number"
                    name={item.key}
                    id={item.key}
                    className="mt-1 block w-full border border-gray200 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                    placeholder="0"
                    value={
                      inputData?.demand?.hourly_demand?.[`${item.key}`]
                        ? inputData?.demand?.hourly_demand?.[`${item.key}`]
                        : ''
                    }
                  />
                </div>
              ))}
            </div>

            <h3 className="text-lg leading-6 font-medium pt-3">PM</h3>
            <div className="grid grid-cols-6 gap-4">
              {timesPM.map((item) => (
                <div key={item.key} className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor={item.key}
                    className="block text-sm font-medium"
                  >
                    {item.value} {loadProfile ? '' : '*'}
                  </label>
                  <input
                    onChange={(e) =>
                      setInputData({
                        type: hourlyDemandType,
                        key: item.key,
                        value: e.target.value,
                      })
                    }
                    type="number"
                    name={item.key}
                    id={item.key}
                    className="mt-1 block w-full border border-gray200 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                    placeholder="0"
                    required={!loadProfile ? true : false}
                    value={
                      inputData?.demand?.hourly_demand?.[`${item.key}`]
                        ? inputData?.demand?.hourly_demand?.[`${item.key}`]
                        : ''
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="flex justify-end">
          <Button
            onClick={(e) => {
              e.preventDefault();
              setTab('Solar Profile');
            }}
          >
            Solar Profile
            <ArrowRightIcon className="inline h-5 ml-3" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoadProfileTab;
