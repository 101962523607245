import SolarPowerUsage from '../components/Charts/SolarUsagePieChart';
import AverageSolarProduction from '../components/Charts/AverageSolarProduction';
import TabContent from '../components/general/TabContent';

/**
 * The solar page
 *
 * @return {JSX.Element}
 */
export default function Solar() {
  return (
    <div className="col-span-12">
      <TabContent
        title="Solar"
        content="Overview of the average solar production and usage for the entire simulation period."
      />
      <section
        id="solarprod-chart"
        className="shadow-micro bg-white rounded-lg py-6 px-4 space-y-6 sm:p-10 mb-6"
      >
        <AverageSolarProduction />
      </section>
      <section
        id="solarpower-chart"
        className="shadow-micro bg-white rounded-lg py-6 px-4 space-y-6 sm:p-10 mb-6"
      >
        <SolarPowerUsage />
      </section>
    </div>
  );
}
