import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { oktaAuth } from '../auth/oktaAuth';

/**
 * Gets the user info
 *
 * @return {array}
 */
export default function useAuthUser() {
  const { authState } = useOktaAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else {
      oktaAuth.token
        .getUserInfo()
        .then((info) => {
          setUser(info);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState]);

  return { user };
}
