import React from "react";

const BASE_CLASSNAMES = [
  "px-3",
  "py-2",
  "mb-2",
  "items-center",
  "font-bold",
  "text-sm",
  "uppercase",
  "inline-flex",
];
const PRIMARY_CLASSNAMES = ["bg-yellow", "hover:bg-black", "hover:text-white"];
const SECONDARY_CLASSNAMES = [
  "bg-error",
  "text-white",
  "hover:bg-black",
  "hover:text-white",
];
const STATE_DISABLED_CLASSNAMES = ["bg-gray100"];

export const Button = ({
  disabled,
  variant = "primary",
  children,
  icon,
  onClick,
  rest,
}) => {
  const VARIANT_CLASSNAMES =
    variant === "primary" ? PRIMARY_CLASSNAMES : SECONDARY_CLASSNAMES;
  const BUTTON_CLASSNAMES = disabled
    ? [...BASE_CLASSNAMES, ...STATE_DISABLED_CLASSNAMES]
    : [...BASE_CLASSNAMES, ...VARIANT_CLASSNAMES];

  return (
    <button
      disabled={disabled}
      className={BUTTON_CLASSNAMES.join(" ")}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};
