import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import Navigation from '../general/Navigation';
import VariableInputTab from './VariableInputTab';
import LoadProfileTab from './LoadProfileTab';
import SolarProfileTab from './SolarProfileTab';
import useInputContext from '../../hooks/useInputContext';
import { prefillInputData } from '../../helpers/InputData';
import { getPreviousConfig } from '../../services/inputDataService';

/**
 * The form component
 *
 * @return {JSX.Element}
 */
export default function Form() {
  const [queryParameters] = useSearchParams();
  const {
    inputData,
    setInputData,
    loadProfile,
    setLoadProfile,
    solarProfile,
    setSolarProfile,
  } = useInputContext();
  const [navigation, setNavigation] = useState([
    { name: 'Load Profile', checked: false },
    { name: 'Solar Profile', checked: false },
    { name: 'Variable input', checked: false },
  ]);
  const [tab, setTab] = useState('Load Profile');
  const [isLoadProfileValid, setIsLoadProfileValid] = useState(false);

  /**
   * Update tab icon
   *
   * @param {string} tabName // Tab to update
   * @param {boolean} checked // Whether tab has valid input or not
   */
  function updateNavigation(tabName, checked) {
    setNavigation((navigation) => {
      const updatedNavigation = navigation.map((navItem) => {
        if (tabName === navItem.name) {
          return { ...navItem, checked: checked };
        }
        return navItem;
      });

      return updatedNavigation;
    });
  }

  // Check whether the load profile is correctly filled in.
  useEffect(() => {
    if (loadProfile || Object.keys(inputData?.demand?.hourly_demand).length === 24) {
      updateNavigation('Load Profile', true);
      setIsLoadProfileValid(true);
    } else {
      updateNavigation('Load Profile', false);
      setIsLoadProfileValid(false);
    }
  }, [loadProfile, inputData]);

  // Check whether the solar profile is correctly filled in.
  useEffect(() => {
    if (solarProfile || (inputData?.solar?.efficiency && inputData?.solar?.surface_area)) {
      updateNavigation('Solar Profile', true);
    } else {
      updateNavigation('Solar Profile', false);
    }
  }, [solarProfile, inputData]);

  useEffect(() => {
    /**
     * Fetch the previous config data.
     * 
     * @param {string} runId The runId
     */
    async function setPreviousConfig(runId) {
      const previousData = await getPreviousConfig(runId);

      prefillInputData(
        runId,
        previousData,
        setInputData,
        setLoadProfile,
        setSolarProfile
      );
    }

    if (queryParameters.get("run_id")) {
      const runIdParam = queryParameters.get("run_id");
      setPreviousConfig(runIdParam);
    }
  }, [queryParameters, setInputData, setLoadProfile, setSolarProfile]);

  return (
    <>
      <Navigation tab={tab} setTab={setTab} navigation={navigation} />
      <form className="sm:px-6 lg:px-0 lg:col-span-10">
        <LoadProfileTab
          tab={tab}
          setTab={setTab}
        />
        <SolarProfileTab
          tab={tab}
          setTab={setTab}
        />
        <VariableInputTab
          tab={tab}
          isLoadProfileValid={isLoadProfileValid}
        />
      </form>
    </>
  );
}
