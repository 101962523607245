/**
 * Loading
 *
 * @return {JSX.Element}
 */
export default function Loading() {
  return (
    <div className="flex items-center flex-wrap justify-center">
      <div
        className="mx-auto relative h-24 w-12 rotate-90
          before:rounded before:h-3 before:left-3 before:mx-auto before:w-1/2 before:border-4 before:border-solid before:border-black before:content-[''] before:absolute
          after:content-[''] after:absolute"
      >
        <div className="battery__container--body rounded border-4 border-solid border-black h-full mt-2 overflow-hidden	relative w-full">
          <span
            className="battery__container--load bg-yellow bottom-0 h-4 absolute w-full
              before:content-[''] before: absolute before:bg-yellow before:h-0 before:top-0 before:left-0
              after:content-[''] after:absolute after:bg-yellow after:h-0 after:top-0 after:right-0"
          ></span>
        </div>
      </div>
    </div>
  );
}
