/**
 * Kpi block
 *
 * @param {string} props // Component properties
 *
 * @return {JSX.Element}
 */
export default function KpiBlock(props) {
  const { title, content, subContent } = props;
  return (
    <>
      <div className="bg-white rounded-lg py-9 px-10 shadow-micro">
        <h4 className="uppercase text-xl font-univers-condensed-bold">
          {title}
        </h4>
        <h3 className="uppercase text-4xl text-micro-genset-curtailed font-univers-condensed-bold">
          {content}
        </h3>
        <h3 className="text-md text-micro-black font-univers pt-2">
          {subContent}
        </h3>
      </div>
    </>
  );
}
