import GridUsageChart from "../components/Charts/GridUsageChart";
import TabContent from '../components/general/TabContent';

/**
 * The grid page
 *
 * @return {JSX.Element}
 */
export default function Grid() {
  return (
    <div className="col-span-12">
      <TabContent
        title="Grid"
        content="Distribution of the local grid power for the entire simulation period."
      />
      <section
        id="grid-usage-chart"
        className="shadow-micro bg-white rounded-lg py-6 px-4 space-y-6 sm:p-10 mb-6"
      >
        <GridUsageChart />
      </section>
    </div>
  );
}
