import {
  batteryNameType,
} from '../../Constants';
import useInputContext from '../../hooks/useInputContext';

/**
 * Submit input data as formdata.
 *
 * @return {JSX.Element}
 */
export function BatterySelect({
  batteryData,
  batteryDataLoading: dataLoading,
}) {
  const { inputData, setInputData } = useInputContext();

  // Update the battery input values.
  const updateBatteryOption = (value) => {
    // Get the object of the selected battery.
    const newValue = batteryData.filter(batObj => batObj.name === value)[0];

    setInputData({
      type: batteryNameType,
      value: newValue,
    });
  };

  return (
    <>
      <div className="col-span-6 sm:col-span-2">
        <label htmlFor="batery-selection" className="block text-sm font-medium">
          Battery selection
        </label>
        <select
          id="batery-selection"
          name="batery-selection"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-md"
          onChange={(e) => updateBatteryOption(e.target.value)}
          value={inputData?.battery?.name ? inputData?.battery?.name : 'No battery'}
        >
          <option>No battery</option>
          {!dataLoading &&
            batteryData.map((value) => <option key={value.name}>{value.name}</option>)}
        </select>
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label htmlFor="batery-capacity" className="block text-sm font-medium">
          Battery capacity in kWh
        </label>
        <input
          id="batery-capacity"
          name="batery-capacity"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none border focus:border-black sm:text-sm rounded-md bg-gray100 border-gray200"
          disabled={true}
          value={inputData?.battery?.capacity ? inputData?.battery?.capacity : '0'}
        />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <label
          htmlFor="inverter-capacity"
          className="block text-sm font-medium"
        >
          Inverter capacity in kW
        </label>
        <input
          type="number"
          name="inverter-capacity"
          id="inverter-capacity"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none border focus:border-black sm:text-sm rounded-md bg-gray100 border-gray200"
          disabled={true}
          value={inputData?.battery?.inverter_capacity ? inputData?.battery?.inverter_capacity : '0'}
        />
      </div>
    </>
  );
}
