import { deleteDataFromApi, postDataToApi, updateDataToApi } from '../dao/api';

/**
 * Update a generator
 *
 * @param {string} id   // The generator ID
 * @param {object} body // The updated generator object
 *
 * @return {Promise}
 */
export async function patchGenerator(id, body) {
  return await updateDataToApi(
    `/generator/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body,
  ).then((res) => {
    return res.json();
  });
}

/**
 * Update a battery
 *
 * @param {string} id   // The battery ID
 * @param {object} body // The updated battery object
 *
 * @return {Promise}
 */
export async function patchBattery(id, body) {
  return await updateDataToApi(
    `/battery/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body,
  ).then((res) => {
    return res.json();
  });
}

/**
 * Create a generator
 *
 * @param {object} body // The updated generator object
 *
 * @return {Promise}
 */
export async function postGenerator(body) {
  return await postDataToApi(
    `/generator/`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body,
  ).then((res) => {
    return res.json();
  });
}

/**
 * Create a battery
 *
 * @param {object} body // The updated battery object
 *
 * @return {Promise}
 */
export async function postBattery(body) {
  return await postDataToApi(
    `/battery/`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    body,
  ).then((res) => {
    return res.json();
  });
}

/**
 * Delete a generator
 *
 * @param {string} id // The generator ID
 *
 * @return {Promise}
 */
 export async function deleteGenerator(id) {
  return await deleteDataFromApi(
    `/generator/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then((res) => {
    return res;
  });
}

/**
 * Delete a battery
 *
 * @param {string} id // The battery ID
 *
 * @return {Promise}
 */
 export async function deletebattery(id) {
  return await deleteDataFromApi(
    `/battery/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then((res) => {
    return res;
  });
}
