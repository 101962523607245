import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Buttons/Button";
/**
 *
 * @return {JSX.Element}
 */
export const NotFound = () => {
  const navigate = useNavigate();

  /**
   * handle button click event handler function
   * @param {Event} e
   * @return {undefined}
   */
  function handleButtonClick(e) {
    e.preventDefault();
    navigate("/");
  }
  return (
    <div className="col-span-full shadow bg-white rounded-lg py-6 px-4 space-y-6 sm:p-6 mb-6">
      <div>
        <h1 className="text-2xl mb-2 leading-6 font-medium pb-2 uppercase">
          Page not found
        </h1>
        <p className="mb-3 text-sm font-univers-light">
          Yikes! The page you are looking for cannot be found.
        </p>
        <Button onClick={handleButtonClick}>Back to the start screen</Button>.
      </div>
    </div>
  );
};
