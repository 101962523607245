import React from 'react';
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { screenWidth } from '../../Constants';
import DownloadChartButton from "../Buttons/DownloadChartButton";
import { theme } from "../../tailwind.config";
import CustomizedLabel from './CustomizedLabel';
import useInputContext from '../../hooks/useInputContext';
import { useChartData } from '../../hooks/useChartData';
import Loading from '../general/Loading';
import { renderLegend } from '../../helpers/renderLegend';
import { CustomTooltipPiechart } from '../../helpers/CustomizedTooltip';

const COLORS = [
  theme.colors['micro-grid'],
  theme.colors['micro-grid-battery'],
  theme.colors['micro-genset-curtailed'],
  theme.colors['micro-load-profile'],
];

/**
 * The RemainingPowerPieChart component
 *
 * @return {JSX.Element}
 */
export default function GridUsageChart() {
  const { runId } = useInputContext();
  const { data, dataLoading, hasError, error } = useChartData(
    runId,
    'remaining_power'
  );
  const chartName = 'Grid usage';
  const elements = document.querySelector('#grid-usage-chart');

  return (
    <>
      <div className="chart-container">
        <div className="flex items-center justify-between pb-8">
          <h2 className="text-xl uppercase">{chartName}</h2>
          {!dataLoading && !!data.length && (
            <DownloadChartButton
              isLoading={dataLoading}
              name={chartName}
              elements={elements}
            />
          )}
        </div>
        {dataLoading ? (
          <Loading />
        ) : hasError ? (
          <p>{error}</p>
        ) : (
          <ResponsiveContainer
            height={screenWidth <= theme.screens.md.value ? 300 : 500}
          >
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                isAnimationActive={true}
                paddingAngle={0}
                outerRadius={screenWidth <= theme.screens.md.value ? 90 : 180}
                label={<CustomizedLabel fill={theme.colors.white} />}
                labelLine={false}
              >
                {/* maps over the data object and returns the piece of the pie chart in the right color */}
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltipPiechart />} />
              <Legend
                formatter={renderLegend}
                align="center"
                verticalAlign="bottom"
                layout="horizontal"
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
}
