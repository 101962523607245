import { useReducer, useState } from 'react';
import { notificationTypeError, notificationTypeSuccess } from '../../Constants';
import useNotificationContext from '../../hooks/useNotificationContext';
import { Button } from '../Buttons/Button';
import AssetInputField from './AssetInputField';

/**
 * Add asset component
 *
 * @return {JSX.Element}
 */
export default function AddAsset({
  setAssets,
  setAssetObject,
  initialAsset,
  assetLabel,
  postAsset,
}) {
  const { setType, setNotification } = useNotificationContext();
  const [addAsset, setAddAsset] = useState(false);
  const [newAsset, setNewAsset] = useReducer(
    setAssetObject,
    initialAsset,
  );
  let inputFields = [];

  if (assetLabel === 'generator') {
    inputFields = [
      {
        label: 'Generator type',
        name: 'generator-type',
        inputType: 'string',
        value: newAsset.type,
      },
      {
        label: 'Purchase price (€)',
        name: 'purchase-price',
        inputType: 'number',
        value: newAsset.price,
      },
      {
        label: 'Operational costs per kWh (€/kWh)',
        name: 'operational-cost',
        inputType: 'number',
        value: newAsset.operational_cost,
      },
      {
        label: 'emmissions CO2 (kg per hour)',
        name: 'emmission-co2',
        inputType: 'number',
        value: newAsset.emmission_co2,
      },
      {
        label: 'emmissions NOx (kg per hour)',
        name: 'emmission-nox',
        inputType: 'number',
        value: newAsset.emmission_NOx,
      },
      {
        label: 'Power rating (kW)',
        name: 'power',
        inputType: 'number',
        value: newAsset.capacity,
      },
      {
        label: 'Generator minimal load ratio (%)',
        name: 'load-ratio',
        inputType: 'number',
        value: newAsset.min_power_perc,
      },
    ];
  }
  if (assetLabel === 'battery') {
    inputFields = [
      {
        label: 'Battery name',
        name: 'battery-name',
        inputType: 'string',
        value: newAsset.name,
      },
      {
        label: 'Purchase price (€)',
        name: 'purchase-price',
        inputType: 'number',
        value: newAsset.price,
      },
      {
        label: 'Battery capacity in kWh',
        name: 'power',
        inputType: 'number',
        value: newAsset.capacity,
      },
      {
        label: 'Inverter capacity in kW',
        name: 'inverter',
        inputType: 'number',
        value: newAsset.inverter_capacity,
      },
      {
        label: 'Minimal SoC (%)',
        name: 'minimal-soc',
        inputType: 'number',
        value: newAsset.min_soc,
      },
      {
        label: 'Maximum SoC (%)',
        name: 'maximum-soc',
        inputType: 'number',
        value: newAsset.max_soc,
      },
      {
        label: 'Discharge factor (%)',
        name: 'discharge-factor',
        inputType: 'number',
        value: newAsset.dcac_efficiency,
      },
    ];
  }

  /**
   * Post the asset to the API.
   */
  function createAsset() {
    postAsset(newAsset).then((res) => {
      setType(notificationTypeSuccess);
      setNotification({
        title: 'Success',
        description: `The ${assetLabel} was successfully created`,
      });
      setAddAsset(false);
      setAssets((prev) => [...prev, res]);
    }).catch((err) => {
      setType(notificationTypeError);
      setNotification({
        title: 'Error',
        description: err.message,
      });
    });
  }

  return (
    <>
      {!addAsset ? (
        <div className="col-span-6 sm:col-span-2 mt-6">
          <Button onClick={() => setAddAsset(!addAsset)}>
            Add {assetLabel}
          </Button>
        </div>
      ) : (
        <div className="grid grid-cols-6 gap-6 ml-8 mt-6">
          {inputFields.map((inputField) => (
            <AssetInputField
              key={inputField.name}
              inputField={inputField}
              setAsset={setNewAsset}
            />
          ))}
          <div className="col-span-6 flex items-end">
            <Button onClick={() => createAsset()}>Create {assetLabel}!</Button>
          </div>
        </div>
      )}
    </>
  );
}
