/**
 * The datepicker and (15minutes/day/month/year) filter description
 *
 * @param {string} props // Component properties
 *
 * @return {JSX.Element}
 */
export default function DatePickerFilter({
  minDate,
  maxDate,
  setStartDate,
  period,
  setPeriod,
  quartersAvailable,
}) {
  return (
    <>
      <div className="col-span-12 pb-4 md:col-span-4 flex md:justify-end">
        <div className="col-span-2">
          <label
            htmlFor="date-selection"
            className="block text-sm font-univers-condensed-bold uppercase"
          >
            Start date
          </label>
          <input
            className="mt-1 block px-2 py-1 pt-1.5 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-lg font-univers"
            type="date"
            defaultValue={minDate}
            min={minDate}
            max={maxDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>

        <div className="col-span-12 md:col-span-2 pl-4">
          <label
            htmlFor="year-selection"
            className="block text-sm font-univers-condensed-bold uppercase"
          >
            Show
          </label>
          <select
            id="year-selection"
            name="year-selection"
            className="mt-1 block px-2 py-2 pt-2 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-lg font-univers"
            defaultValue={period}
            onChange={(e) => setPeriod(e.target.value)}
          >
            <option>Year</option>
            <option>Month</option>
            <option>Day-hours</option>
            {quartersAvailable && <option>Day</option>}
          </select>
        </div>
      </div>
    </>
  );
}
