import { getDataFromApi } from '../dao/api';

/**
 * Get the chart data for a specific chart.
 *
 * @param {string} runId     // The ID of the current input
 * @param {string} endpoint  // The endpoint to get data from
 * @param {string} period    // The selected period to use
 * @param {string} startDate // The selected startDate to pass
 *
 * @return {array} // The chart data
 */
export async function getChartData(runId, endpoint, period, startDate) {
  const periodParam = period ? `&period=${period}` : '';
  const startDateParam = startDate ? `&start_date=${startDate}` : '';

  return await getDataFromApi(
    `/tool/${endpoint}?run_id=${runId}${periodParam}${startDateParam}`
  ).then((res) => {
    return res.json();
  });
}
