import { postDataToApi, getDataFromApi } from '../dao/api';

/**
 * Submit input data as formdata.
 *
 * @param {object} inputData // The input data.
 * @param {file} loadProfile // The load profile file
 * @param {file} solarProfile // The solar profile file
 *
 * @return {string} // The ID of the configuration
 */
export async function postInputData(inputData, loadProfile, solarProfile) {
  const formData = new FormData();

  // If the type is available, we know that a file is uploaded.
  if (solarProfile?.type) {
    formData.append('solar_profile_file', solarProfile);
  }
  if (loadProfile?.type) {
    formData.append('load_profile_file', loadProfile);
  }
  formData.append('data', JSON.stringify(inputData));

  return await postDataToApi(`/tool/run_tool`, {}, formData).then((res) => {
    return res.json();
  });
}

/**
 * Gets the Generator data
 *
 * @return {Promise}
 */
export async function getGeneratorData() {
  return await getDataFromApi(`/generator/`).then((res) => {
    return res.json();
  });
}
/**
 * Gets the Battery data
 *
 * @return {Promise}
 */
export async function getBatteryData() {
  return await getDataFromApi(`/battery/`).then((res) => {
    return res.json();
  });
}

/**
 * Get filter options.
 *
 * @param {string} runId // The ID of the current input
 *
 * @return {Promise}
 */
export async function getFilterOptions(runId) {
  return await getDataFromApi(`/tool/filters?run_id=${runId}`).then((res) => {
    return res.json();
  });
}

/**
 * Get previous configuration data.
 *
 * @param {string} runId // The ID of the previous configuration
 *
 * @return {Promise}
 */
export async function getPreviousConfig(runId) {
  return await getDataFromApi(`/tool/previous_config?run_id=${runId}`).then(
    (res) => {
      return res.json();
    }
  );
}
