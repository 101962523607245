import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  generatorType,
  gridPowerExportType,
  gridPowerImportType,
  initialScenario,
  maximumScenarios,
  notificationTypeError,
  notificationTypeSuccess,
  purchasingPriceType,
  scenarioType,
  sellingPriceType,
  TIME_TO_READ,
} from "../../Constants";
import { BatterySelect } from './BatterySelect';
import { GeneratorSelect } from './GeneratorSelect';
import { ScenarioSelect } from './ScenarioSelect';
import useNotificationContext from '../../hooks/useNotificationContext';
import useInputContext from '../../hooks/useInputContext';
import { postInputData } from '../../services/inputDataService';
import { useAssets } from '../../hooks/useAssets';
import { wait } from "../../helpers";
import { Button } from "../Buttons/Button";

const FORM_IDLE = 'FORM_IDLE';
const FORM_PENDING = 'FORM_PENDING';

/**
 * The 'Variable input' tab
 *
 * @return {JSX.Element}
 */
const VariableInputTab = ({ tab, isLoadProfileValid }) => {
  const navigate = useNavigate();
  const { setRunId, inputData, setInputData, loadProfile, solarProfile } =
    useInputContext();
  const { setType, setNotification } = useNotificationContext();
  const {
    data: generatorData,
    dataLoading,
    hasError,
    error,
  } = useAssets('generator');
  const [generatorSet, setGeneratorSet] = useState(inputData?.generators);
  const [scenarios, setScenarios] = useState(inputData?.scenarios_per_timeframe);
  const { data: batteryData, batteryDataLoading } = useAssets('battery');
  const [formState, setFormState] = useState(FORM_IDLE);

  const isPending = formState === FORM_PENDING;

  /**
   * Add generator.
   *
   * @param {object} e // Event
   */
  function addGenerator(e) {
    e.preventDefault();

    setGeneratorSet((prevState) => [...prevState, {}]);
  }

  /**
   * Add generator.
   *
   * @param {object} e // Event
   */
  function addScenario(e) {
    e.preventDefault();

    setScenarios((prevState) => [...prevState, initialScenario]);
  }

  /**
   * Remove generator.
   *
   * @param {object} e // Event
   * @param {number} index // Generator index
   */
  function removeGenerator(e, index) {
    e.preventDefault();

    setGeneratorSet((prevState) => {
      return prevState.filter((generator, key) => key !== index);
    });
  }

  /**
   * Remove scenario.
   * 
   * @param {object} e // Event
   * @param {number} index // Scenario index
   */
  function removeScenario(e, index) {
    e.preventDefault();

    setScenarios((prevState) => {
      return prevState.filter((scenario, key) => key !== index);
    });
  }

  useEffect(() => {
    // Check whether we have prefilled data from a shared URL.
    if (inputData?.run_id) {
      if (inputData?.generators) {
        setGeneratorSet(inputData?.generators);
      }
      if (inputData?.scenarios_per_timeframe) {
        setScenarios(inputData?.scenarios_per_timeframe);
      }
    }
  }, [inputData]);

  useEffect(() => {
    setInputData({ type: generatorType, value: generatorSet });
  }, [generatorSet, setInputData]);

  useEffect(() => {
    setInputData({ type: scenarioType, value: scenarios });
  }, [scenarios, setInputData]);

  return (
    <div className={'Variable input' === tab ? '' : 'hidden'}>
      <div className="shadow bg-white rounded-lg lg:rounded-tl-none py-6 px-4 space-y-6 sm:p-6 mb-10">
        <div>
          <h1 className="text-2xl leading-6 pb-2 uppercase">MicroGrid variable inputs</h1>
          <p className="mt-1 text-sm font-univers-light">
            Please enter the following information to calculate the microgrid
          </p>
        </div>

        <div className="grid grid-cols-6 gap-6">
          {batteryDataLoading && <p>Loading Batteries</p>}
          <BatterySelect
            batteryData={batteryData}
            batteryDataLoading={batteryDataLoading}
          />

          <div className="col-span-6 sm:col-span-2">
            <label
              htmlFor="battery-selection"
              className="block text-sm font-medium"
            >
              Max Grid power in kW (Import)
            </label>
            <input
              type="number"
              name="batery-selection"
              id="batery-selection"
              className="mt-1 block w-full border border border-gray200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e) =>
                setInputData({
                  type: gridPowerImportType,
                  value: e.target.value,
                })
              }
              value={
                inputData?.grid?.max_grid_import
                  ? inputData?.grid?.max_grid_import
                  : ''
              }
            />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <label
              htmlFor="inverter-capacity"
              className="block text-sm font-medium"
            >
              Max Grid power in kW (Export)
            </label>
            <input
              type="number"
              name="inverter-capacity"
              id="inverter-capacity"
              className="mt-1 block w-full border border border-gray200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e) =>
                setInputData({
                  type: gridPowerExportType,
                  value: e.target.value,
                })
              }
              value={
                inputData?.grid?.max_grid_export
                  ? inputData?.grid?.max_grid_export
                  : ''
              }
            />
          </div>

          <div className="col-span-6 sm:col-span-2"></div>

          <div className="col-span-6 sm:col-span-2">
            <label
              htmlFor="purchasing-price"
              className="block text-sm font-medium"
            >
              Purchasing price per kWh (€/kWh)
            </label>
            <input
              type="number"
              name="purchasing-price"
              id="purchasing-price"
              className="mt-1 block w-full border border border-gray200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e) =>
                setInputData({
                  type: purchasingPriceType,
                  value: e.target.value,
                })
              }
              value={
                inputData?.grid?.price_per_kwh_import
                  ? inputData?.grid?.price_per_kwh_import
                  : ''
              }
            />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <label
              htmlFor="selling-price"
              className="block text-sm font-medium"
            >
              Selling price per kWh (€/kWh)
            </label>
            <input
              type="number"
              name="selling-price"
              id="selling-price"
              className="mt-1 block w-full border border border-gray200 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e) =>
                setInputData({
                  type: sellingPriceType,
                  value: e.target.value,
                })
              }
              value={
                inputData?.grid?.price_per_kwh_export
                  ? inputData?.grid?.price_per_kwh_export
                  : ''
              }
            />
          </div>

          <div className="col-span-6 sm:col-span-2" />

          {dataLoading && <p>Loading Generators</p>}
          {generatorSet.map((generator, i) => {
            return (
              <GeneratorSelect
                key={i}
                index={i}
                generator={generator}
                generatorData={generatorData}
                hasError={hasError}
                error={error}
                setGeneratorSet={setGeneratorSet}
                removeGenerator={removeGenerator}
              />
            );
          })}

          {scenarios.map((scenario, i) => {
            return (
              <ScenarioSelect
                key={i}
                index={i}
                scenario={scenario}
                setScenarios={setScenarios}
                removeScenario={removeScenario}
              />
            );
          })}

          <div className="col-span-6 sm:col-span-2">
            <Button onClick={(e) => addGenerator(e)}>Add generator</Button>
            {scenarios?.length <= maximumScenarios - 1 &&
              <span className="ml-4"><Button onClick={(e) => addScenario(e)}>Add scenario</Button></span>
            }
          </div>
        </div>

        <div className="flex justify-end">
          <Button
            disabled={isPending}
            onClick={(e) => {
              e.preventDefault();
              if (!isLoadProfileValid) {
                const description = 'Please add a load profile.';

                setType(notificationTypeError);
                setNotification({
                  title: 'Oops! Something went wrong.',
                  description,
                });
              } else {
                setFormState(FORM_PENDING);
                setType(notificationTypeSuccess);
                setNotification({
                  title: 'Sending',
                  description: 'Your parameters are being sent...',
                });
                wait(TIME_TO_READ).then(() => {
                  postInputData(inputData, loadProfile, solarProfile)
                    .then((res) => {
                      setRunId(res);
                      setType(notificationTypeSuccess);
                      setNotification({
                        title: 'Success',
                        description: 'Redirecting you to your results...',
                      });
                      wait(TIME_TO_READ).then(() => {
                        navigate('/overview');
                      });
                    })
                    .catch((err) => {
                      setType(notificationTypeError);
                      setNotification({
                        title: 'Error',
                        description:
                          'The parameters cannot be processed right now. Please try again later',
                      });
                    })
                    .finally(() => {
                      setFormState(FORM_IDLE);
                    });
                });
              }
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VariableInputTab;
