import PowerBalance from '../components/Charts/PowerBalance';
import MaxPowerDelivery from "../components/Charts/MaxPowerDelivery";
import TabContent from '../components/general/TabContent';

/**
 * The power page
 *
 * @return {JSX.Element}
 */
export default function Power() {
  return (
    <div className="col-span-12">
      <TabContent
        title="Power"
        content="Breakdown of the total energy supply of the available sources for entire period of simulation."
      />
      <section
        id="powerbalance-chart"
        className="shadow-micro bg-white rounded-lg py-6 px-4 sm:p-10 mb-6"
      >
        <PowerBalance />
      </section>
      <section
        id="maxpowerdelivery-chart"
        className="shadow-micro bg-white rounded-lg py-6 px-4 space-y-6 sm:p-10 mb-6"
      >
        <MaxPowerDelivery />
      </section>
    </div>
  );
}
