import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { notificationTypeError } from "../../Constants";
import useNotificationContext from "../../hooks/useNotificationContext";

/**
 * The notification component
 *
 * @return {JSX.Element}
 */
export default function Notification() {
  const {type, notification} = useNotificationContext();

  if (!notification.title) {
    return null;
  }

  return (
    <div className='shadow bg-white rounded p-4 fixed mt-8 flex md:right-8'>
      {type === notificationTypeError ?
        <ExclamationCircleIcon className="w-6 h-6 mr-4 text-error"/>
        :
        <CheckCircleIcon className="w-6 h-6 mr-4 text-success"/>
      }
      <div>
        <p><strong>{notification.title}</strong></p>
        <p>{notification.description}</p>
      </div>
    </div>
  );
}
